import { t, Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocalStorageValue } from "@react-hookz/web";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useGetCurrentPatientQuery } from "../onboarding/get-current-patient.graphql";
import { colors } from "../theme";
import { useContactZymegoMutation } from "./contact-zymego.graphql";
import { useGetAppointmentsQuery } from "./get-appointments.graphql";
import { useGetCareUnitByNameQuery } from "./get-care-unit-by-name.graphql";
import { useGetCareUnitsQuery } from "./get-care-units.graphql";

type ContactZymegoFormProperties = {
  onClose: () => void;
};

interface FormValues {
  email: string;
  message: string;
  preferredCareUnitSelection: string;
}

function ContactZymegoForm(properties: ContactZymegoFormProperties) {
  const theme = useTheme();

  const [searchParameters] = useSearchParams();
  const newAppointmentCareUnitUrlFriendlyName =
    searchParameters.get("clinicName") ?? "";
  const hasNewAppointmentCareUnit =
    newAppointmentCareUnitUrlFriendlyName !== "";
  const {
    data: newAppointmentCareUnitData,
    loading: newAppointmentCareUnitLoading,
    error: newAppointmentCareUnitError,
  } = useGetCareUnitByNameQuery({
    variables: { careUnitName: newAppointmentCareUnitUrlFriendlyName },
    skip: !hasNewAppointmentCareUnit,
  });

  const {
    data: appointmentsData,
    error: appointmentsError,
    loading: appointmentsLoading,
  } = useGetAppointmentsQuery();

  const {
    data: { patient } = {},
    error: patientError,
    loading: patientLoading,
  } = useGetCurrentPatientQuery();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const [contactZymegoMutation, { loading, error, data }] =
    useContactZymegoMutation();

  const {
    data: careUnitsData,
    error: careUnitsError,
    loading: careUnitsLoading,
  } = useGetCareUnitsQuery();

  const { value: defaultCareUnitSelection, set: setDefaultCareUnitSelection } =
    useLocalStorageValue<string>("defaultCareUnitSelection", {
      defaultValue: "",
    });

  if (
    careUnitsLoading ||
    appointmentsLoading ||
    newAppointmentCareUnitLoading
  ) {
    return null;
  }

  if (
    error ??
    careUnitsError ??
    newAppointmentCareUnitError ??
    appointmentsError ??
    patientError
  ) {
    throw (
      error ??
      careUnitsError ??
      newAppointmentCareUnitError ??
      appointmentsError ??
      patientError
    );
  }

  if (!careUnitsData) {
    throw new ReferenceError("Care units query did not return any data.");
  }

  if (!appointmentsData) {
    throw new ReferenceError("Appointments query did not return any data.");
  }

  const noAppointments = (appointmentsData.appointments ?? []).length === 0;
  const careUnitList = careUnitsData.careUnits ?? [];

  const onSubmit = async (values: FormValues) => {
    if (values.preferredCareUnitSelection) {
      setDefaultCareUnitSelection(values.preferredCareUnitSelection);
    }
    await contactZymegoMutation({
      variables: {
        email: values.email,
        message: values.message,
        careUnitName:
          newAppointmentCareUnitData?.careUnitByUrlFriendlyName?.name ??
          values?.preferredCareUnitSelection,
      },
    });
  };

  if (data?.contactZymego?.success === true) {
    return (
      <Box
        sx={{
          padding: 3,
          textAlign: "center",
          minHeight: "18rem",
          fontFamily: "Gellix",
        }}
      >
        <Typography
          color="black"
          data-cy="message-sent-successfully"
          fontSize="22px"
          fontWeight="700"
          marginBottom="2rem"
        >
          <Trans>Your message has been sent successfully!</Trans>
        </Typography>
        <Typography
          color={colors.zymegoDarkGreen}
          fontSize="16px"
          fontWeight="700"
          marginBottom="2rem"
          marginX="2rem"
          sx={{
            opacity: 0.6,
          }}
        >
          <Trans>We normally reply within 24 hours.</Trans>
        </Typography>
        <Button
          data-cy="contact-support-close-button"
          fullWidth
          onClick={properties.onClose}
          variant="contained"
        >
          <Trans>Close</Trans>
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: { sm: 3, xs: 2 },
        textAlign: "center",
        fontFamily: "Gellix",
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography color="black" fontSize={22} fontWeight={700}>
          <Trans>Zymego support</Trans>
        </Typography>
        <Typography
          color={colors.zymegoDarkGreen}
          fontSize="18px"
          fontWeight={700}
          marginY="1rem"
          sx={{
            opacity: "60%",
          }}
        >
          <Trans>Write your message in the box below.</Trans>
        </Typography>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "7px",
            background: "rgba(248,244,231,0.2)",
            backgroundColor: "rgba(252, 222, 222, 0.25)",
            padding: {
              xs: 1,
              sm: 2,
            },
          }}
        >
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              marginRight: theme.direction === "ltr" ? "8px" : "0px",
              marginLeft: theme.direction === "rtl" ? "8px" : "0px",
              backgroundColor: colors.zymegoRose,
              color: colors.zymegoDarkGreen,
              fontSize: "large",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "50%",
              padding: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <PriorityHighIcon sx={{ fontSize: 20 }} />
          </Avatar>

          <Typography
            color={colors.zymegoDarkGreen}
            fontSize="17px"
            sx={{
              textAlign: theme.direction === "rtl" ? "right" : "left",
            }}
          >
            <Trans>
              <b>
                We don't accept cancellations and reschedules through this
                contact form.
              </b>
            </Trans>
          </Typography>
        </Box>
        {noAppointments && !hasNewAppointmentCareUnit && (
          <div>
            <FormControl fullWidth>
              <InputLabel id="labelCareUnit" sx={{ opacity: "60%" }}>
                <Trans>Clinic</Trans>
              </InputLabel>

              <Controller
                control={control}
                defaultValue={defaultCareUnitSelection}
                name="preferredCareUnitSelection"
                render={({ field }) => (
                  <Select
                    {...field}
                    data-cy="contact-support-care-unit-selection"
                    id="selCareUnit"
                    label={t`Clinic`}
                    labelId="labelCareUnit"
                    variant="outlined"
                  >
                    {careUnitList.map(({ id, name }) => (
                      <MenuItem
                        data-cy={`select-option-${name}`}
                        key={id}
                        value={name}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{
                  required: t`A clinic selection is required.`,
                }}
              />
              {errors.preferredCareUnitSelection && (
                <FormHelperText error>
                  <Trans>A clinic selection is required.</Trans>
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}

        <Controller
          control={control}
          defaultValue={patient?.email ?? ""}
          name="email"
          render={({ field }) => (
            <TextField
              disabled={loading || patientLoading || patient?.email !== null}
              sx={{ direction: "ltr" }}
              {...field}
              InputLabelProps={{
                sx: { opacity: "60%" },
              }}
              data-cy="contact-support-email"
              error={
                Boolean(errors.email) || control.getFieldState("email").invalid
              }
              fullWidth
              helperText={errors.email?.message}
              inputMode="email"
              label={t`Email`}
              onChange={(event) => field.onChange(event.target.value)}
              rows={8}
              size="medium"
              type="email"
            />
          )}
          rules={{
            required: t`Email is required.`,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t`Please provide a valid email address.`,
            },
          }}
        />

        <Controller
          control={control}
          defaultValue={""}
          name="message"
          render={({ field }) => (
            <TextField
              disabled={loading}
              sx={{ direction: "ltr" }}
              {...field}
              InputLabelProps={{ sx: { opacity: "60%" } }}
              data-cy="contact-support-message"
              error={
                Boolean(errors.message) ||
                control.getFieldState("message").invalid
              }
              fullWidth
              helperText={errors.message?.message}
              label={t`Message`}
              multiline
              onChange={(event) => field.onChange(event.target.value)}
              rows={8}
              size="medium"
            />
          )}
          rules={{
            required: t`Message is required.`,
          }}
        />
        <LoadingButton
          data-cy="contact-support-send-button"
          disabled={!isValid}
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight fill="currentColor" />
            ) : (
              <ArrowLeft fill="currentColor" />
            )
          }
          fullWidth
          loading={loading}
          type="submit"
          variant="contained"
        >
          <Trans>Send</Trans>
        </LoadingButton>
      </form>
    </Box>
  );
}

export { ContactZymegoForm };
