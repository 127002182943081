import { Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ConsentForConfirmationsAndRemindersField } from "../consent/consent-for-confirmations-and-reminders-field";
import { isFeatureEnabled } from "../feature-flags";
import { useUrls } from "../urls";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { OnboardingView } from "./onboarding-view";
import { useUpdatePatientConsentMutation } from "./update-patient-consent.graphql";

interface FormValues {
  consentForConfirmationsAndReminders?: "true" | "false";
}

function ConfirmationsAndRemindersConsentInfo({
  hideOtherSteps,
}: {
  hideOtherSteps?: boolean;
}) {
  const navigate = useNavigate();
  const urls = useUrls();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  const [updatePatientConsent, { error: mutationError }] =
    useUpdatePatientConsentMutation();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<FormValues>();

  if (!isFeatureEnabled("consentForConfirmationsAndReminders") || loading) {
    navigate(urls.index);
    return null;
  }

  if (error ?? mutationError) {
    throw error ?? mutationError;
  }

  if (!patient) {
    throw new ReferenceError("Query did not return a patient.");
  }

  return (
    <OnboardingView
      hideOtherSteps={hideOtherSteps}
      isValid={isValid}
      step="consent"
    >
      <OnboardingView.DescriptionText>
        <Trans>
          We need your consent for Zymego to send out booking confirmations and
          reminders. You always have the right to withdraw your consent.
        </Trans>
      </OnboardingView.DescriptionText>
      <OnboardingView.Stepper />
      <OnboardingView.Icon icon="envelope" />
      <OnboardingView.Form
        handleSubmit={handleSubmit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmit={async (values): Promise<void> => {
          await updatePatientConsent({
            variables: {
              id: patient.id,
              consentForConfirmationsAndReminders:
                values.consentForConfirmationsAndReminders === "true",
            },
          });
        }}
      >
        <ConsentForConfirmationsAndRemindersField control={control} />
      </OnboardingView.Form>
    </OnboardingView>
  );
}

export { ConfirmationsAndRemindersConsentInfo };
