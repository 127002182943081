import {
  FormControlLabel,
  Radio as MuiRadio,
  SxProps,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

import { colors, theme } from "../../theme";
import { RadioGroup } from "./radio-group";

type RadioProperties = {
  label: ReactNode;
  sx?: SxProps;
  value: boolean | number | string;
};

const styles: SxProps = {
  flexGrow: 1,
  justifyContent: "center",
  margin: 0,
  position: "relative",
  "& .MuiRadio-root::after": {
    backgroundColor: colors.zymegoDarkGreen,
    borderRadius: 999,
    content: '""',
    height: 0,
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    transition: theme.transitions.create(["height", "width"]),
    width: 0,
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: colors.zymegoSpearMint,
    border: `3px solid ${colors.zymegoSpearMint}`,
    borderRadius: 999,
    height: 31,
    transition: theme.transitions.create(["border-color"]),
    width: 31,
    "& path": {
      display: "none",
    },
  },
  "& .MuiRadio-root.Mui-checked": {
    "&::after": {
      height: 13,
      width: 13,
    },
    "& .MuiSvgIcon-root": {
      border: `3px solid ${colors.zymegoWarmGreen}`,
    },
  },
};

function Radio({ label, sx, value }: RadioProperties) {
  return (
    <FormControlLabel
      control={<MuiRadio color="secondary" />}
      key={String(value)}
      label={
        <Typography
          sx={{
            color: colors.zymegoDarkGreen,
            fontSize: 17,
            fontWeight: 600,
            marginLeft: 1,
            userSelect: "none",
          }}
          variant="boldSubtitle1"
        >
          {label}
        </Typography>
      }
      sx={{ ...styles, ...sx } as SxProps}
      value={value}
    />
  );
}

Radio.Group = RadioGroup;

export { Radio };
