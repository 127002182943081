import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { isWishlistEnabled } from "../utils";
import { useCancellationRequestMutation } from "./cancellation-request.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function CancelReason() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
  // TODO: Use react-hook-form instead
  const [reason, setReason] = useState<string>("");
  const navigate = useNavigate();

  invariant(appointmentId, "appointmentId is missing from url");

  const { data: { appointment } = {} } = useGetAppointmentQuery({
    variables: { id: appointmentId },
  });

  const [
    cancellationRequest,
    { loading: mutationLoading, error: mutationError },
  ] = useCancellationRequestMutation();

  if (mutationError) {
    throw mutationError;
  }

  const handleCancelRequest = async () => {
    const { data: cancellationData } = await cancellationRequest({
      variables: {
        appointmentId,
        reason,
      },
    });

    navigate(
      `../status/${cancellationData?.cancelAppointment?.cancelAppointmentRequest?.id}`,
      { state: { backgroundLocation } },
    );
  };

  const minCharacters = 10;
  const buttonDisabled =
    !mutationLoading && reason.replace(/\s/g, "").length < minCharacters;

  const remainingCharacters = minCharacters - reason.replace(/\s/g, "").length;
  const errorMessage =
    remainingCharacters > 0
      ? t`You need to type ${remainingCharacters} more characters to continue...`
      : "";

  let wishlistEnabled = false;
  if (appointment && appointment.careUnit && appointment.bookingType) {
    const { careUnit, bookingType } = appointment;
    wishlistEnabled = isWishlistEnabled({ careUnit, bookingType });
  }

  return (
    <DialogBottom
      onClose={() => setIsDialogOpen(false)}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            lineHeight: "18px",
          }}
          underline="none"
        >
          {theme.direction === "ltr" ? (
            <ArrowBackIos fontSize="small" />
          ) : (
            <ArrowForwardIos fontSize="small" />
          )}
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              paddingY: { xs: 1, sm: 2 },
              paddingX: 3,
            }}
          >
            <Typography variant="h5">
              <Trans>State reason</Trans>
            </Typography>
            <Typography
              align="center"
              color={colors.zymegoStone}
              sx={{
                paddingY: 2,
              }}
              variant="h6"
            >
              {wishlistEnabled ? (
                <Trans>Why don't you need a new appointment?</Trans>
              ) : (
                <Trans>
                  If you want a new appointment, you are responsible for
                  contacting the clinic yourself.
                </Trans>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "left",
              borderRadius: 3,
              opacity: "60%",
              backgroundColor: colors.zymegoPowder,
              padding: {
                xs: 1,
                sm: 2,
              },
              gap: 2,
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: colors.zymegoRose,
                color: colors.zymegoDarkGreen,
                fontSize: "large",
              }}
            >
              <PriorityHighIcon sx={{ fontSize: 20 }} />
            </Avatar>

            <Typography variant="h6">
              <Trans>
                <Trans>Questions to the clinic will not be answered</Trans>
              </Trans>
            </Typography>
          </Box>
          <Box sx={{ paddingX: 1 }}>
            <TextField
              error={Boolean(errorMessage)}
              fullWidth
              helperText={errorMessage}
              label={
                wishlistEnabled
                  ? t`State why you don't need a new appointment`
                  : t`Cancellation reason`
              }
              maxRows={5}
              minRows={4}
              multiline
              onChange={(event) => setReason(event.target.value)}
              style={{
                width: "98%",
                borderColor: colors.zymegoSand,
                borderRadius: "4px",
              }}
              value={reason}
            />
          </Box>

          <Box
            sx={{
              paddingX: 3,
            }}
          >
            <Button
              color="primary"
              disabled={buttonDisabled}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              onClick={() => {
                handleCancelRequest();
              }}
              size="large"
              variant="contained"
            >
              <Trans>Confirm cancellation</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogBottom>
  );
}
export { CancelReason };
