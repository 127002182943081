import { Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, Grid, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../components/button/button";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ReactComponent as ZymegoLogo } from "../icons/zymego-logo.svg";
import { colors, theme } from "../theme";
import { useUrls } from "../urls";
import { newAppointmentFlowByLocation } from "../utils";
import { useGetConsentQuery } from "./get-consent.graphql";
import { useGiveConsentMutation } from "./give-consent.graphql";

function Consent() {
  const navigate = useNavigate();
  const location = useLocation();
  const urls = useUrls();

  const {
    error,
    loading,
    data: { currentPatient } = {},
  } = useGetConsentQuery();

  const [giveConsent, { error: mutationError, loading: mutationLoading }] =
    useGiveConsentMutation();

  if (loading) {
    return null;
  }

  if (error ?? mutationError) {
    throw error ?? mutationError;
  }

  if (!currentPatient) {
    throw new ReferenceError("Query did not return a patient.");
  }

  return (
    <Dialog
      aria-describedby="consent-dialog-description"
      aria-labelledby="consent-dialog-title"
      open={true}
      scroll="body"
    >
      <Grid
        alignItems="stretch"
        container
        direction="column"
        flexGrow={1}
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Grid
          item
          sx={{
            textAlign: "center",
          }}
        >
          <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>
            <ZymegoLogo
              style={{
                fill: colors.zymegoGreen,
                marginTop: 3,
                marginBottom: 3,
              }}
            />
          </Box>
          <Typography
            align="center"
            data-cy="consent-dialog-title"
            fontWeight={500}
            id="consent-dialog-title"
            sx={{ marginBottom: 2, color: colors.zymegoGreen }}
            variant="h4"
          >
            <Trans>We need your consent</Trans>
          </Typography>
          <Typography
            align="center"
            fontWeight={400}
            id="consent-dialog-description"
            lineHeight="22.4px"
            sx={{
              marginBottom: "40px",
              color: colors.zymegoGreen,
            }}
            variant="subtitle1"
          >
            <Trans>
              To use Zymego you need to accept our{" "}
              <Link
                href={urls.termsOfUse}
                sx={{
                  color: colors.zymegoDarkGreen,
                  textDecorationColor: colors.zymegoWarmGreen,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                target="_blank"
              >
                Terms of use
              </Link>{" "}
              and confirm that you agree with our{" "}
              <Link
                href={urls.privacyPolicy}
                sx={{
                  color: colors.zymegoDarkGreen,
                  textDecorationColor: colors.zymegoWarmGreen,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
                target="_blank"
              >
                Privacy policy
              </Link>
            </Trans>
          </Typography>
          <Typography
            align="left"
            color={colors.zymegoStone}
            fontSize="10px"
            fontWeight={400}
            lineHeight="16.3px"
            padding={1}
          >
            <Trans>
              * By clicking “approve” you accept our Terms of use and Privacy
              policy. Our Terms of use outlines the rules for using our
              services.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            color="primary"
            data-cy="consent-dialog-approve-button"
            endIcon={
              theme.direction === "ltr" ? (
                <ArrowRight style={{ fill: "currentColor" }} />
              ) : (
                <ArrowLeft style={{ fill: "currentcolor" }} />
              )
            }
            fullWidth
            loading={mutationLoading}
            onClick={async () => {
              await giveConsent({
                variables: {
                  patientId: currentPatient?.id,
                },
              });

              navigate({
                pathname: newAppointmentFlowByLocation(location)
                  ? "/bookings/new"
                  : "/",
                search: location.search,
              });
            }}
            variant="contained"
          >
            <Trans>Approve</Trans>
          </LoadingButton>

          <Box>
            <Button
              design="transparent"
              href="/logout"
              icon="logout"
              sx={{ fontSize: "13px", fontWeight: 700, opacity: 0.6 }}
            >
              <Trans>Sign out</Trans>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export { Consent };
