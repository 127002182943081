import { Box } from "@mui/material";
import { ReactNode } from "react";

import { DialogBottom } from "../../dialog-bottom";
import { DrawerCloseButton } from "./drawer-close-button";
import { DrawerHandle } from "./drawer-handle";

function Drawer({
  children,
  isOpen,
  onClose,
  onExit,
}: {
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onExit?: () => void;
}) {
  return (
    <DialogBottom onClose={onClose} onExited={onExit} open={isOpen}>
      {onClose && <DrawerCloseButton onClose={onClose} />}
      <Box sx={{ paddingBottom: "24px", paddingTop: "48px", paddingX: "24px" }}>
        <DrawerHandle />
        {children}
      </Box>
    </DialogBottom>
  );
}

export { Drawer };
