query GetCaregivers($careUnitUrlFriendlyNames: [String!]) {
  careUnits(filter: { urlFriendlyName: { in: $careUnitUrlFriendlyNames } }) {
    id
    caregivers {
      id
      externalIdHash
      name
    }
  }
}
