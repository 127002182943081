import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, TextField, Typography } from "@mui/material";
import { normalise, parse } from "personnummer.js";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Drawer } from "../components/drawer/drawer";
import { useBackgroundLocation } from "../router/background-location-context";
import { getConfig } from "../runtime-config";
import { useCreateProfileMutation } from "./create-profile.graphql";

interface FormValues {
  patientIdentifier: string;
}

const { DEPENDANT_MAX_AGE_EXCLUSIVE } = getConfig();

function AddGuardianshipDialog({
  careUnitUrlFriendlyName,
}: {
  careUnitUrlFriendlyName?: string;
}) {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const location = useLocation();
  const previousLocation = location.pathname.slice(
    0,
    location.pathname.lastIndexOf("/"),
  );
  const [createProfile, { data, error, loading }] = useCreateProfileMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  if (data) {
    navigate(
      previousLocation +
        `/guardianship/${data?.createProfile?.validateAndCreateGuardianSessionRequest?.id}/status` +
        location.search,
    );
  }

  const onSubmit = (formValues: FormValues) => {
    const normalizedPatientIdentifier = normalise(formValues.patientIdentifier);

    if (normalizedPatientIdentifier === undefined) {
      return;
    }

    createProfile({
      variables: {
        patientIdentifier: normalizedPatientIdentifier,
        ...(careUnitUrlFriendlyName ? { careUnitUrlFriendlyName } : undefined),
      },
      refetchQueries: "active",
    });
  };

  return (
    <Drawer
      isOpen={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExit={() => {
        navigate(
          { pathname: backgroundLocation.pathname, search: location.search },
          { replace: true },
        );
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: 1.3,
            marginBottom: "8px",
            textAlign: "center",
          }}
        >
          <Trans>Enter the child's personal identity number</Trans>
        </Typography>
        <Controller
          control={control}
          defaultValue=""
          name="patientIdentifier"
          render={({ field }) => (
            <TextField
              placeholder={t`YYYYMMDD-XXXX`}
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.patientIdentifier)}
              fullWidth={true}
              helperText={errors.patientIdentifier?.message}
              label={t`Personal identity number`}
              size="medium"
            />
          )}
          rules={{
            validate(value) {
              if (value?.length === 0) {
                return t`Personal identity number is required.`;
              }

              const parsedPatientIdentifier = parse(value);

              if (parsedPatientIdentifier.valid === false) {
                return t`Please provide a valid personal identity number.`;
              }

              if (
                parsedPatientIdentifier.age &&
                parsedPatientIdentifier.age >= DEPENDANT_MAX_AGE_EXCLUSIVE
              ) {
                return t`The child must be under the age of ${DEPENDANT_MAX_AGE_EXCLUSIVE}.`;
              }

              return true;
            },
          }}
        />
        {error ? (
          <Alert severity="error">
            <Trans>Guardianship could not be validated.</Trans>
          </Alert>
        ) : null}
        <LoadingButton
          endIcon={<ArrowForward />}
          fullWidth
          loading={loading}
          loadingPosition="end"
          sx={{ marginBottom: 0 }}
          type="submit"
          variant="contained"
        >
          <Trans>Confirm</Trans>
        </LoadingButton>
      </form>
    </Drawer>
  );
}
export { AddGuardianshipDialog };
