import { Box, FormHelperText, FormLabel, SxProps } from "@mui/material";
import { ReactNode } from "react";

export function Fieldset({
  children,
  errorMessage,
  label,
  sx,
}: {
  children: ReactNode;
  errorMessage?: string;
  label: string;
  sx?: SxProps;
}) {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      <FormLabel
        sx={{
          backgroundColor: "white",
          color: "gray",
          fontSize: "1rem",
          left: 0,
          margin: "0 0.5rem",
          padding: "0 0.3rem",
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          transform: "translateY(-50%) scale(.9)",
          transformOrigin: "left top",
        }}
      >
        {label}
      </FormLabel>
      <Box
        sx={{
          border: "2px solid #E6E6E6",
          borderRadius: "6px",
          padding: "0.5rem 0.7rem",
        }}
      >
        {children}
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
