import { i18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { PriorityHigh } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import {
  arEG as localizationArEG,
  enUS as localizationEnUS,
  Localization,
  svSE as localizationSvSE,
} from "@mui/material/locale";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import {
  enUS as pickersEnUS,
  svSE as pickersSvSE,
} from "@mui/x-date-pickers/locales";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { PropsWithChildren } from "react";
import { getLangDir } from "rtl-detect";

import { Direction } from "./direction";
import GellixBoldWoff2 from "./fonts/Gellix-Bold.woff2";
import GellixMediumWoff2 from "./fonts/Gellix-Medium.woff2";
import GellixRegularWoff2 from "./fonts/Gellix-Regular.woff2";
import GellixSemiBoldWoff2 from "./fonts/Gellix-SemiBold.woff2";
import { localizationCyGB } from "./locales/cy/mui-localization";

declare module "@mui/material/Button" {
  // eslint-disable-next-line unicorn/prevent-abbreviations
  interface ButtonPropsVariantOverrides {
    dialog: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    boldSubtitle1: React.CSSProperties;
    boldBody2: React.CSSProperties;
    info: React.CSSProperties;
    boldInfo: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    boldSubtitle1?: React.CSSProperties;
    boldBody2?: React.CSSProperties;
    info?: React.CSSProperties;
    boldInfo?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  // eslint-disable-next-line unicorn/prevent-abbreviations
  interface TypographyPropsVariantOverrides {
    boldSubtitle1: true;
    boldBody2: true;
    info: true;
    boldInfo: true;
  }
}

const coreLocaleMap: { [locale: string]: Localization } = {
  "ar-EG": localizationArEG,
  "cy-GB": localizationCyGB,
  "en-US": localizationEnUS,
  "sv-SE": localizationSvSE,
  ar: localizationArEG,
  cy: localizationCyGB,
  en: localizationEnUS,
  sv: localizationSvSE,
};

const coreLocale = coreLocaleMap[i18n.locale] ?? localizationEnUS;

const pickersLocaleMap: { [locale: string]: typeof pickersEnUS } = {
  "en-US": pickersEnUS,
  "sv-SE": pickersSvSE,
  en: pickersEnUS,
  sv: pickersSvSE,
};

const pickersLocale = pickersLocaleMap[i18n.locale] ?? pickersEnUS;

const colors = {
  black: "#000000",
  grey: "#D6D6D6",
  white: "#FFFFFF",
  red: "#F64F4F",
  blue: "#479FFF",
  zymegoDarkGreen: "#122A2C",
  zymegoGreen: "#224C4F",
  zymegoAccessibleGreen: "#2A8B86",
  zymegoWarmGreen: "#33AFA9",
  zymegoDarkMint: "#ADB9AF",
  zymegoMint: "#DDF3DE",
  zymegoSpearMint: "#E2F1EE",
  zymegoOrange: "#FF6B00",
  zymegoRose: "#FDBDC8",
  zymegoPowder: "#FCDEDE",

  // zymegoPowder with 0.25 opacity on white background
  // used in MessageBox, which is shown both on white and grey backgrounds,
  // so can't just use opacity
  zymegoPowderLight: "#FEF5F5",

  zymegoBluePowder: "#C9EBFF",
  zymegoStone: "#605E56",
  zymegoSand: "#C3B8B0",
  zymegoTravertine: "#E9DBB7",
  zymegoLightTravertine: "#F8F4E7",
  zymegoGranite: "#6B6B74",
  zymegoLightGreen: "#E1F0ED",
};

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 425,
        lg: 768,
        xl: 1024,
      },
    },
    palette: {
      primary: {
        main: colors.zymegoOrange,
        contrastText: colors.white,
      },
      secondary: {
        main: colors.zymegoWarmGreen,
        contrastText: colors.white,
      },
      text: {
        primary: colors.black,
        secondary: colors.white,
      },
      info: {
        main: colors.zymegoDarkGreen,
        contrastText: colors.white,
      },
    },
    typography: {
      fontFamily: "'Gellix', 'Helvetica', 'Arial', sans-serif",
      h1: {
        fontSize: "2.375rem", // 38px
        fontWeight: 700,
      },
      h2: {
        fontSize: "1.9375rem", // 31px
        fontWeight: 700,
      },
      h3: {
        fontSize: "1.5625rem", // 25px
        fontWeight: 700,
      },
      h4: {
        fontSize: "1.375rem", // 22px
        fontWeight: 700,
      },
      h5: {
        fontSize: "1.25rem", // 20px
        fontWeight: 700,
      },
      h6: {
        fontSize: "1.125rem", // 18px
        fontWeight: 700,
      },
      info: {
        fontSize: "1.063rem", // 17px
      },
      boldInfo: {
        fontSize: "1.063rem", // 17px
        fontWeight: 700,
      },
      boldBody2: {
        fontSize: "0.813rem", // 13px
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "1rem", // 16px
      },
      boldSubtitle1: {
        fontSize: "1rem", // 16px
        fontWeight: 700,
      },
      subtitle2: {
        fontSize: "0.875rem", // 14px
      },
      body1: {
        fontSize: "1rem", // 16px
      },
      body2: {
        fontSize: "0.8125rem", // 13px
      },
      button: {
        fontSize: "1.125rem", // 18px
      },
      caption: {
        fontSize: "0.75rem", // 12px
      },
      overline: {
        fontSize: "0.75rem", // 12px
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Gellix';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Gellix'), local('Gellix-Regular'), url(${GellixRegularWoff2}) format('woff2');
          }
          @font-face {
            font-family: 'Gellix';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Gellix'), local('Gellix-Medium'), url(${GellixMediumWoff2}) format('woff2');
          }
          @font-face {
            font-family: 'Gellix';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('Gellix'), local('Gellix-SemiBold'), url(${GellixSemiBoldWoff2}) format('woff2');
          }
          @font-face {
            font-family: 'Gellix';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Gellix'), local('Gellix-Bold'), url(${GellixBoldWoff2}) format('woff2');
          }
          body {
            background: #fafafa;
          }
        `,
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            background: colors.zymegoSpearMint,
            borderRadius: "8px",
            border: `3px solid ${colors.zymegoSpearMint}`,
            padding: "11px 13px",
            lineHeight: 1,
            fontSize: "16px",
            fontWeight: 700,
            color: colors.zymegoGreen,
            "&.Mui-selected": {
              borderColor: colors.zymegoWarmGreen,
              background: colors.white,
              "&:hover": {
                background: colors.white,
              },
            },
            "&:hover": {
              background: colors.white,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected ": {
              background: colors.white,
              borderColor: colors.zymegoDarkGreen,
              "&:hover ,&:focus": {
                background: colors.zymegoSand,
                borderColor: colors.zymegoDarkGreen,
              },
            },
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            margin: "0.5rem 0",
            fontWeight: "700",
            ".MuiButton-startIcon>*:nth-of-type(1)": {
              fontSize: 24,
            },
            ".MuiButton-endIcon>*:nth-of-type(1)": {
              fontSize: 24,
            },
          },

          contained: {
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "9px",
          },
          outlined: {
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "9px",
          },

          sizeSmall: {
            fontSize: "1.063rem", // 17px
            padding: "6px 16px",
          },
          sizeMedium: {
            fontSize: "1.125rem", // 18px
            padding: "13px 24px",
          },
          sizeLarge: {
            fontSize: "1.3125rem", // 21px
            padding: "11px 24px",
          },
        },
        variants: [
          {
            props: { variant: "dialog" },
            style: {
              textTransform: "none",
              border: "none",
              margin: 0,
              padding: "24px",
            },
          },
        ],
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "13px",
            padding: "24px",
            maxWidth: "444px",
            margin: "24px",
          },
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.65)",
          },
        },
      },

      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "13px",
            boxShadow: "0px 16px 16px -11px rgba(0, 0, 0, 0.05)",
            padding: 0,
            color: colors.zymegoDarkGreen,
          },
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            padding: "0.4em",
            color: "#ffffff",
            background: "#00A5A4",
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: colors.zymegoDarkGreen,
            fontWeight: 700,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "0.5em",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#00A5A4",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: colors.zymegoDarkGreen,
            fontWeight: 700,
            fontSize: "0.75rem",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            marginTop: "1em",
            marginBottom: "1em",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "6px",
            borderWidth: "2px",
            borderColor: "#E6E6E6",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginTop: "1em",
            marginBottom: "1em",
          },
        },
      },
      // @ts-expect-error TODO: Fix mismatching MUI dependency versions.
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            width: "100%",
            "&.makeStyles-calendar-6": {
              width: "100%",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&:hover,&:focus": {
              borderRadius: "5px",
            },
            "&.Mui-disabled": {
              caretColor: "gray",
              background: "white",
              color: "#DCDCDC",
            },
            "&.Mui-selected": {
              "&:hover,&:focus": { background: colors.zymegoWarmGreen },
              background: colors.zymegoWarmGreen,
            },
          },
        },
      },

      MuiAlert: {
        defaultProps: {
          iconMapping: {
            warning: (
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: colors.zymegoPowder,
                  color: colors.black,
                  fontSize: "large",
                }}
              >
                <PriorityHigh sx={{ fontSize: 20 }} />
              </Avatar>
            ),
            info: (
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: colors.zymegoTravertine,
                  color: colors.black,
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: 700 }}>
                  i
                </Typography>
              </Avatar>
            ),
          },
        },
        styleOverrides: {
          root: {
            margin: "8px",
            borderRadius: "7px",
            fontSize: "1rem", // 16px
            "&.MuiAlert-standardInfo": {
              backgroundColor: colors.zymegoLightTravertine,
            },
            "&.MuiAlert-standardWarning": {
              color: colors.black,
              backgroundColor: colors.zymegoPowderLight,
            },
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            // same as H6
            fontSize: "1.125rem", // 18px
            fontWeight: 700,
          },
        },
      },
    },
  },
  pickersLocale,
  coreLocale,
);

type Properties = Record<string, unknown>;

function ThemeComponent({ children }: PropsWithChildren<Properties>) {
  const {
    i18n: { locale },
  } = useLingui();

  const direction = getLangDir(locale);

  theme.direction = direction;

  return (
    <Direction direction={direction}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </Direction>
  );
}

export { colors, theme, ThemeComponent };
