import { Trans } from "@lingui/macro";
import { Box, Button, Typography } from "@mui/material";

import { Drawer } from "../components/drawer/drawer";
import { InfoBox } from "../info-box/info-box";
import { colors } from "../theme";

interface RevisitInfoDialogProperties {
  isOpen: boolean;
  onConfirm: () => void;
}

function RevisitInfoDialog({ isOpen, onConfirm }: RevisitInfoDialogProperties) {
  return (
    <Drawer isOpen={isOpen}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={1}
          textAlign="center"
        >
          <Typography
            sx={{
              color: colors.zymegoDarkGreen,
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            <Trans>Select caregiver</Trans>
          </Typography>
          <Typography
            sx={{
              color: colors.zymegoDarkGreen,
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            <Trans>
              On return visits, it is important that you choose the same
              caregiver as on your previous visit. If the caregiver is not in
              the list, contact your clinic.
            </Trans>
          </Typography>
        </Box>
        <InfoBox
          sx={{
            fontSize: "16px",
            margin: { sm: 0 },
            padding: { sm: 0 },
          }}
        >
          <Trans>
            A different caregiver might not know your history and treatment
            plan.
          </Trans>
        </InfoBox>
        <Button
          fullWidth
          onClick={onConfirm}
          sx={{ margin: 0 }}
          type="button"
          variant="contained"
        >
          <Trans>Select caregiver</Trans>
        </Button>
      </Box>
    </Drawer>
  );
}

export { RevisitInfoDialog };
