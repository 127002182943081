import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { colors } from "../theme";
import { splitByComma } from "../utils";
import { useFetchTimeslotsNewAppointmentMutation } from "./fetch-timeslots.graphql";
import { useGetCareUnitsWithBookingTypesQuery } from "./get-care-units-with-booking-types.graphql";
import { ListOfRadioButtons } from "./list-of-radio-buttons";

interface FormValues {
  id: string;
}

interface BookingType {
  id: string;
  externalIdHash: string;
  canBookNew: boolean;
  name: string;
  visibleName: string | null;
  isRevisit: boolean;
}

function FooterSection({
  data,
  disabled,
}: {
  data: FormValues;
  disabled: boolean;
}) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          background: "white",
          boxShadow: "0 -40px 40px white",
          margin: "auto",
          maxWidth: "444px",
          textAlign: "center",
        }}
      >
        <Box sx={{ paddingBottom: "24px", paddingX: "24px" }}>
          {!data && (
            <Typography color={colors.red} marginTop="auto" variant="subtitle2">
              <Trans>Please select one option to move forward</Trans>
            </Typography>
          )}
          <Button
            disabled={disabled}
            endIcon={<ArrowForward />}
            fullWidth
            sx={{ marginBottom: 0 }}
            type="submit"
            variant="contained"
          >
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function TypeOfAppointment() {
  const [searchParameters] = useSearchParams();

  const careUnitNames = splitByComma(searchParameters.get("clinicName"));
  invariant(
    careUnitNames && careUnitNames.length > 0,
    "careUnit name(s) is not provided",
  );

  const bookingTypeIds = splitByComma(searchParameters.get("bookingTypeId"));

  const navigate = useNavigate();
  const location = useLocation();

  const { control, handleSubmit, watch } = useForm<FormValues>();
  const data = watch();

  const [fetchTimeslots, { loading: mutationLoading, data: mutationData }] =
    useFetchTimeslotsNewAppointmentMutation();

  const { loading: getCareUnitLoading, data: { careUnits } = {} } =
    useGetCareUnitsWithBookingTypesQuery({
      // TODO: Only fetch the booking types that can be booked
      variables: { careUnitUrlFriendlyNames: careUnitNames },
    });

  useEffect(() => {
    if (bookingTypeIds.length > 0 && careUnits) {
      fetchTimeslots({
        variables: {
          careUnitIds: careUnits.map((unit) => unit.id),
        },
      });
      if (mutationData?.fetchTimeslotsNewAppointmentBatch?.uuid) {
        searchParameters.set(
          "requestId",
          mutationData?.fetchTimeslotsNewAppointmentBatch?.uuid,
        );

        if (
          careUnits
            .flatMap((careUnit) => careUnit.bookingTypes)
            .find(({ id }) => bookingTypeIds.includes(id))?.isRevisit
        ) {
          searchParameters.set("isRevisit", "true");
        }

        navigate({
          pathname: "../select-caregiver",
          search: `${searchParameters}`,
        });
      }
    }
  }, [
    searchParameters,
    bookingTypeIds,
    careUnits,
    fetchTimeslots,
    mutationData,
    location,
    navigate,
  ]);

  if (!careUnits && getCareUnitLoading) {
    return null;
  }

  if (!careUnits || careUnits.length === 0) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`There are no available time slots right now`}
        errorMessage={t`Please contact the care units directly. You will find their contact details on their respective homepages.`}
      />
    );
  }

  // Check if all care units have no available booking types
  const unavailableCareUnits = careUnits.filter(
    (unit) => !unit.bookingTypes || unit.bookingTypes.length === 0,
  );

  if (unavailableCareUnits.length === careUnits.length) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`There are no available time slots for any care units right now`}
        errorMessage={[
          ...unavailableCareUnits.map(
            (unit) =>
              t`Please contact ${unit.name}. You will find contact details on the homepage: ${unit.externalHomePageUrl}.`,
          ),
        ].join("\n")}
      />
    );
  }

  if (mutationLoading) {
    return <Loading logo={false} text="loading" />;
  }

  const onSubmit = async ({ id: bookingTypeExternalId }: FormValues) => {
    const bookingTypesByExternalId = careUnits
      .flatMap((unit) => unit.bookingTypes)
      //eslint-disable-next-line unicorn/no-array-reduce, unicorn/prefer-object-from-entries, max-params
      .reduce<{ [externalIdHash: string]: BookingType[] }>(
        //eslint-disable-next-line  max-params
        (accumulator, bookingType) => {
          const { externalIdHash } = bookingType as BookingType;
          accumulator[externalIdHash] ??= [];
          //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          accumulator[externalIdHash]!.push(bookingType);
          return accumulator;
        },
        {},
      );

    const request = await fetchTimeslots({
      variables: {
        careUnitIds: careUnits.map((unit) => unit.id),
      },
    });

    const ids = bookingTypesByExternalId[bookingTypeExternalId]
      ?.map((bookingType) => bookingType.id)
      .join(",");

    if (ids) {
      searchParameters.set("bookingTypeId", ids);
    }

    request.data?.fetchTimeslotsNewAppointmentBatch?.uuid &&
      searchParameters.set(
        "requestId",
        request.data.fetchTimeslotsNewAppointmentBatch.uuid,
      );

    navigate({
      pathname: "../select-caregiver",
      search: `${searchParameters}`,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "auto", display: "flex" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={1}
        sx={{ paddingX: { xs: 2, sm: 3 } }}
        textAlign="center"
      >
        <Box
          sx={{
            color: colors.zymegoDarkGreen,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography
            sx={{ fontSize: "22px", fontWeight: 600, marginBottom: "16px" }}
          >
            <Trans>Select type of visit</Trans>
          </Typography>
        </Box>
        <ListOfRadioButtons
          formProperties={{ control }}
          properties={_.uniqBy(
            careUnits
              .flatMap((unit) => unit.bookingTypes)
              .filter(({ canBookNew }) => canBookNew)
              .map(({ externalIdHash, visibleName, name, isRevisit }) => ({
                id: externalIdHash,
                name: visibleName ?? name,
                isRevisit,
              })),
            "id",
          )}
        />
        <FooterSection data={data} disabled={Object.keys(data).length === 0} />
      </Box>
    </form>
  );
}
export { TypeOfAppointment };
