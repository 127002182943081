import { Box, Grid, SxProps } from "@mui/material";

import { colors } from "../../theme";
import { Icon, IconName } from "../icon/icon";

type IconCircleColor = "error" | "success";

type IconCircleSize = "md" | "xxl";

const iconCircleSizes = {
  md: "24px",
  xxl: "116px",
};

const iconCircleThemes = {
  error: {
    backgroundColor: colors.zymegoPowder,
    textColor: colors.red,
  },
  success: {
    backgroundColor: colors.zymegoSpearMint,
    textColor: colors.zymegoWarmGreen,
  },
};

export function IconCircle({
  color,
  icon,
  size = "md",
  sx,
}: {
  color?: IconCircleColor;
  icon: IconName;
  size?: IconCircleSize;
  sx?: SxProps;
}) {
  const iconCircleSize = iconCircleSizes[size];
  const theme = color ? iconCircleThemes[color] : undefined;

  return (
    <Grid
      item
      sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: theme?.backgroundColor ?? "#EFEFEE",
          borderRadius: "100%",
          color: theme?.textColor ?? "#CECECF",
          display: "flex",
          height: iconCircleSize,
          justifyContent: "center",
          margin: "8px auto",
          transition: "all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95)",
          width: iconCircleSize,
          ...sx,
        }}
      >
        <Icon name={icon} size="lg" />
      </Box>
    </Grid>
  );
}
