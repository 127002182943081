import { Trans } from "@lingui/macro";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { Button as MuiButton, SxProps } from "@mui/material";
import { ComponentType, ReactNode } from "react";

import { colors, theme } from "../../theme";
import { Icon, IconName } from "../icon/icon";

type ButtonDesign = "primary" | "transparent" | "white-bordered";

type ButtonSize = "small" | "medium" | "large";

function getStyles(design: ButtonDesign) {
  const styles = {
    primary: {},
    transparent: {
      backgroundColor: "transparent",
      color: colors.zymegoGreen,
      "&:active, &:hover": {
        backgroundColor: "#DBDBDB",
        boxShadow: "none",
      },
    },
    "white-bordered": {
      backgroundColor: colors.white,
      border: `2px solid ${colors.grey}`,
      color: colors.black,
      "&:active, &:hover": {
        backgroundColor: "#F0F0F0",
        boxShadow: "none",
      },
    },
  };
  return styles[design];
}

function Button({
  children,
  "data-cy": testId,
  design = "primary",
  disabled,
  href,
  icon,
  loading,
  onClick,
  size,
  sx,
  type,
}: {
  children: ReactNode;
  "data-cy"?: string;
  design?: ButtonDesign;
  disabled?: boolean;
  href?: string;
  icon?: IconName;
  loading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  sx?: SxProps;
  type?: "button" | "reset" | "submit";
}) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const Element: ComponentType<any> = loading ? MuiLoadingButton : MuiButton;

  return (
    <Element
      {...(loading && { loading, loadingPosition: "end" })}
      color="primary"
      data-cy={testId}
      disabled={disabled}
      endIcon={icon && <Icon name={icon} />}
      fullWidth
      href={href}
      onClick={onClick}
      size={size}
      sx={{ ...getStyles(design), ...sx }}
      type={type}
      variant="contained"
    >
      {children}
    </Element>
  );
}

Button.Next = function NextButton({
  children,
  "data-cy": testId,
  disabled,
  loading,
  onClick,
  size,
}: {
  children?: ReactNode;
  "data-cy"?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
}) {
  return (
    <Button
      data-cy={testId}
      disabled={disabled}
      icon={theme.direction === "ltr" ? "arrowRight" : "arrowLeft"}
      loading={loading}
      onClick={onClick}
      size={size}
      type="submit"
    >
      {children ?? <Trans>Next</Trans>}
    </Button>
  );
};

export { Button };
