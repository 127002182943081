import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { colors } from "../theme";
import { logError, splitByComma } from "../utils";
import { useBookingTypes } from "./booking-types-context";
import { RevisitInfoDialog } from "./dialog-revisit-info";
import { useGetCaregiversQuery } from "./get-caregivers.graphql";
import { ListOfRadioButtons } from "./list-of-radio-buttons";
import { useCareUnitsAndTimeslots } from "./use-care-units-and-timeslots";

type FormValues = {
  id: string;
};

function SelectCaregiver() {
  const { bookingTypes } = useBookingTypes();
  const { control, handleSubmit } = useForm<FormValues>();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const bookingTypeId = searchParameters.get("bookingTypeId");
  const bookingTypeIds = splitByComma(bookingTypeId);
  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );
  const caregiverId = searchParameters.get("caregiverId");
  const earliestStartAt = searchParameters.get("earliestStartAt");
  const fetchTimeslotRequestId = searchParameters.get("requestId");
  const latestEndAt = searchParameters.get("latestEndAt");

  invariant(bookingTypeId);
  invariant(careUnitUrlFriendlyNames.length > 0);
  invariant(fetchTimeslotRequestId);

  const [isRevisitInfoDialogOpen, setIsRevisitInfoDialogOpen] = useState(
    Boolean(!caregiverId && bookingTypes?.some(({ isRevisit }) => isRevisit)),
  );

  const query = useCareUnitsAndTimeslots({
    skip: Boolean(caregiverId),
    variables: {
      bookingTypeIds,
      careUnitUrlFriendlyNames,
      earliestStartAt: earliestStartAt ? parseISO(earliestStartAt) : undefined,
      fetchTimeslotRequestId,
      latestEndAt: latestEndAt ? parseISO(latestEndAt) : undefined,
    },
  });

  // We need to fetch the timeslots before fetching the caregivers as the timeslots query first
  // synchronizes the caregivers from the external EHR system.
  const { data: caregiversData, loading: caregiversLoading } =
    useGetCaregiversQuery({
      fetchPolicy: "network-only",
      skip: Boolean(caregiverId) || query.status !== "success",
      variables: { careUnitUrlFriendlyNames },
    });

  if (caregiverId) {
    return <Navigate to={`../select-timeslot${location.search}`} />;
  }

  if (query.status === "loading" || caregiversLoading)
    return (
      <Loading
        logo={false}
        text={t`We are fetching available time slots from the patient record system. This may take a while.`}
      />
    );

  if (query.status === "error") {
    logError(query.error);
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={query.errorMessage?.header}
        errorMessage={query.errorMessage?.message}
      />
    );
  }

  const { careUnits, timeslots } = query;
  const bookingType = _.uniq(bookingTypes?.map((type) => type.name));
  const caregivers =
    caregiversData?.careUnits?.flatMap((careUnit) => careUnit.caregivers) ?? [];

  const allCaregivers = _.uniqBy(
    caregivers.map((caregiver) => ({
      id: caregiver.externalIdHash,
      name: caregiver.name,
    })),
    "id",
  );

  const activeCaregivers = allCaregivers.filter((caregiver) =>
    timeslots.some(
      (timeslot) => caregiver.id === timeslot.caregiver?.externalIdHash,
    ),
  );

  if (activeCaregivers.length === 0) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`There are no available time slots right now`}
        errorMessage={careUnits
          .map(
            (unit) =>
              t`Please contact ${unit.name}. You will find contact details on the homepage: ${unit.externalHomePageUrl}.`,
          )
          .join("\n")}
      />
    );
  }

  const onSubmit = ({ id: caregiverExternalId }: FormValues) => {
    const caregiverIds = caregivers
      .filter((caregiver) => caregiver.externalIdHash === caregiverExternalId)
      .map((caregiver) => caregiver.id)
      .join(",");

    const caregiverIdParameter =
      caregiverExternalId === "" ? "all" : caregiverIds;

    navigate({
      pathname: "../select-timeslot",
      search: `${location.search}&caregiverId=${caregiverIdParameter}`,
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: "auto", display: "flex" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={1}
          sx={{ paddingX: { xs: 2, sm: 3 } }}
          textAlign="center"
        >
          <Box
            sx={{
              color: colors.zymegoDarkGreen,
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginBottom: "12px",
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
              <Trans>Select caregiver</Trans>
            </Typography>
            <Typography sx={{ fontSize: "19px", fontWeight: 400 }}>
              {bookingType}
            </Typography>
          </Box>
          <ListOfRadioButtons
            formProperties={{ control }}
            properties={activeCaregivers}
            selectAll={
              !bookingTypes?.some(
                ({ newAppointmentHideAllCaregiversOption }) =>
                  newAppointmentHideAllCaregiversOption,
              )
            }
          />
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                background: "white",
                boxShadow: "0 -40px 40px white",
                margin: "auto",
                maxWidth: "444px",
                textAlign: "center",
              }}
            >
              <Box sx={{ paddingBottom: "24px", paddingX: "24px" }}>
                <Button
                  endIcon={<ArrowForward />}
                  fullWidth
                  sx={{ marginBottom: 0 }}
                  type="submit"
                  variant="contained"
                >
                  <Trans>Next</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      <RevisitInfoDialog
        isOpen={isRevisitInfoDialogOpen}
        onConfirm={() => setIsRevisitInfoDialogOpen(false)}
      />
    </>
  );
}

export { SelectCaregiver };
