import { Trans } from "@lingui/macro";
import { Link } from "@mui/material";

import { colors } from "../../theme";
import { Icon } from "../icon/icon";

function DrawerCloseButton({ onClose }: { onClose: () => void }) {
  return (
    <Link
      color={colors.zymegoDarkGreen}
      onClick={onClose}
      sx={{
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        fontSize: 14,
        fontWeight: 600,
        gap: "4px",
        left: "12px",
        opacity: 0.6,
        position: "absolute",
        top: "12px",
      }}
      underline="none"
    >
      <Icon name="chevronLeft" />
      <Trans>Close</Trans>
    </Link>
  );
}

export { DrawerCloseButton };
