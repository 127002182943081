import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { useAuthLevel } from "../nhs/user-auth-level";
import { formatPatientIdentifier } from "../patient/identifier";
import { useFormPersist } from "../react-hook-form-persist";
import { getConfig } from "../runtime-config";
import { getIdentifierPlaceholder, logError } from "../utils";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

interface FormValues {
  reason: string;
}

const { AUTH_IDENTITY_PROVIDER, GEOGRAPHY } = getConfig();

const maxReasonLength = 250;

function BookingInformationNewAppointment() {
  const { authLevel } = useAuthLevel();
  const { formatDate, formatTime } = useDateFormatter();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");
  const {
    loading,
    error,
    data: { timeslot } = {},
  } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery({ fetchPolicy: "network-only" });

  const navigate = useNavigate();
  const location = useLocation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  useFormPersist({ key: "patient-information", watch, setValue });
  const data = watch();

  if (currentPatientLoading && !patient) {
    return null;
  }

  if (error || (!timeslot && !loading) || !patient || currentPatientError) {
    if (error ?? currentPatientError) {
      logError(error ?? currentPatientError);
    } else if (!timeslot && !loading) {
      logError("Timeslot query did not return a timeslot.");
    } else if (!patient) {
      logError("Current patient query did not return a patient.");
    }

    return <ErrorComponent component="new-booking-error" />;
  }

  const bookingTypeName =
    timeslot?.bookingType?.visibleName ?? timeslot?.bookingType?.name;

  const onSubmit = async () => {
    navigate({ pathname: "../contact-information", search: location.search });
  };

  const patientDisplayIdentifier = formatPatientIdentifier(patient.identifier, {
    maskIdentifier: authLevel !== "high",
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "auto", display: "flex" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "space-between",
          paddingBottom: 3,
          paddingX: 3,
          textAlign: "center",
        }}
      >
        <Box>
          {timeslot ? (
            <Box sx={{ marginBottom: "16px" }}>
              {timeslot.careUnit && (
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  <Trans>{timeslot.careUnit.name}</Trans>
                </Typography>
              )}
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  textAlign: "center",
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {formatDate(new Date(timeslot.startAtInCareUnitsTimezone))}{" "}
                &bull;{" "}
                {formatTime(new Date(timeslot.startAtInCareUnitsTimezone))}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                <Trans>with</Trans> {timeslot.caregiver?.name}
                {bookingTypeName ? `, ${bookingTypeName}` : ""}
              </Typography>
            </Box>
          ) : undefined}

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={patient.identifier}
            fullWidth
            inputProps={{ readOnly: true }}
            label={
              AUTH_IDENTITY_PROVIDER === "nhs"
                ? t`NHS number`
                : t`Personal identity number`
            }
            placeholder={getIdentifierPlaceholder(GEOGRAPHY)}
            size="medium"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#ADB9AF26",
              },
            }}
            value={patientDisplayIdentifier}
          />

          <Controller
            control={control}
            defaultValue=""
            name="reason"
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data.reason}
                error={Boolean(errors.reason)}
                fullWidth
                helperText={errors.reason?.message}
                inputProps={{ maxLength: maxReasonLength }}
                label={
                  t`Reason for appointment` +
                  " " +
                  t`(${field.value.length} / ${maxReasonLength} characters)`
                }
                multiline
                onChange={(event) => field.onChange(event.target.value)}
                placeholder={t`Describe your issues as clear as you can`}
                rows={7}
                size="medium"
              />
            )}
            rules={{
              required: t`Reason is required.`,
            }}
          />
        </Box>
        <Button
          endIcon={<ArrowForward />}
          fullWidth
          sx={{ marginBottom: 0 }}
          type="submit"
          variant="contained"
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </form>
  );
}

export { BookingInformationNewAppointment };
