import { Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ReactComponent as TwoArrows } from "../icons/two-arrows.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { isWishlistEnabled } from "../utils";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function Cancellation() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId: id } = useParams();

  invariant(id);

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const {
    loading,
    error,
    data: { appointment } = {},
  } = useGetAppointmentQuery({
    variables: { id },
  });

  const navigate = useNavigate();

  if (error) {
    throw error;
  }

  if (!loading && !appointment) {
    throw new ReferenceError("Query did not return an appointment.");
  }

  let wishlistEnabled = false;
  if (appointment && appointment.careUnit && appointment.bookingType) {
    const { careUnit, bookingType } = appointment;
    wishlistEnabled = isWishlistEnabled({ careUnit, bookingType });
  }
  const to = wishlistEnabled ? "./another-appointment" : "../request";

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() =>
            navigate("../../manage", {
              state: { backgroundLocation },
              replace: true,
            })
          }
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingY: { xs: 1, sm: 2 },
          paddingX: {
            xs: 3,
            sm: 4,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography textAlign="center" variant="h5">
          <Trans>Start cancellation and go through the entire flow</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          component={Link}
          disabled={
            (appointment?.rescheduleAppointmentRequestsByAppointmentId ?? [])
              .length > 0
          }
          fullWidth
          replace
          size="large"
          to="../../reschedule"
          variant="dialog"
        >
          <Box
            sx={{
              margin: "auto",
              display: "inline-flex",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",

                color: colors.zymegoWarmGreen,
                backgroundColor: colors.zymegoSpearMint,
                marginLeft: "auto",
              }}
            >
              <TwoArrows style={{ fill: "currentColor" }} />
            </Avatar>
            <Trans>I want to reschedule instead</Trans>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingY: {
            xs: 1,
            sm: 2,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <LoadingButton
          color="primary"
          component={Link}
          data-cy="proceed-to-cancellation-button"
          disabled={loading}
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          fullWidth
          loading={loading}
          size="large"
          state={{ backgroundLocation }}
          to={to}
          variant="contained"
        >
          <Trans>Start cancellation</Trans>
        </LoadingButton>
      </Box>
    </DialogBottom>
  );
}
export { Cancellation };
