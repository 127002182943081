import { t } from "@lingui/macro";
import { Grid } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import { Radio } from "../components/radio/radio";
import { colors } from "../theme";

function ListOfRadioButtons({
  properties,
  formProperties,
  selectAll,
}: {
  properties?: Array<{ id: string; name: string | null }>;
  formProperties: { control: Control<{ id: string }> };
  selectAll?: boolean;
}) {
  return (
    <Grid
      container
      sx={{
        border: `2px solid ${colors.grey}`,
        borderRadius: "12px",
        direction: "column",
        marginBottom: "128px",
      }}
    >
      <Controller
        control={formProperties.control}
        defaultValue={""}
        name="id"
        render={({ field }) => {
          return (
            <Radio.Group {...field} defaultValue="">
              {selectAll && (
                <Grid
                  item
                  sx={{
                    borderBottom: `1px solid ${colors.grey}`,
                    paddingX: "12px",
                    paddingY: "4px",
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  <Radio label={t`All caregivers`} value="" />
                </Grid>
              )}
              {properties?.map((property) => (
                <Grid
                  item
                  key={property.id}
                  sx={{
                    borderBottom: `1px solid ${colors.grey}`,
                    paddingX: "12px",
                    paddingY: "4px",
                    textAlign: "start",
                    width: "100%",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Radio label={property.name} value={property.id} />
                </Grid>
              ))}
            </Radio.Group>
          );
        }}
      />
    </Grid>
  );
}

export { ListOfRadioButtons };
