import { t, Trans } from "@lingui/macro";
import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

import { Alert } from "../components/alert/alert";
import { Button } from "../components/button/button";
import { AuthLevel } from "../nhs/user-auth-level";
import { State } from "../router/state";
import { getConfig } from "../runtime-config";
import { colors } from "../theme";
import { startSignIn } from "./sign-in";

function AuthShieldAlertBody({
  requiredAuthLevel,
}: {
  requiredAuthLevel: AuthLevel;
}) {
  const { AUTH_IDENTITY_PROVIDER } = getConfig();

  if (AUTH_IDENTITY_PROVIDER === "nhs") {
    switch (requiredAuthLevel) {
      case "low":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h5">
              You cannot continue without sharing your information
            </Typography>
            <Typography variant="body1">
              <Trans>
                <strong>Zymego</strong> needs your NHS login information to
                verify your identity.
              </Trans>
            </Typography>
            <Typography variant="body1">
              <Trans>
                If you need medical help, go to{" "}
                <AuthShieldLink href="https://111.nhs.uk/">
                  111.nhs.uk
                </AuthShieldLink>{" "}
                or call <AuthShieldLink href="tel:111">111</AuthShieldLink> or
                your GP.
              </Trans>
            </Typography>
            <Typography variant="body1">
              <Trans>
                Call <AuthShieldLink href="tel:999">999</AuthShieldLink> if it's
                a life-threatening emergency.
              </Trans>
            </Typography>
          </Box>
        );
      case "medium":
        return (
          <Trans>
            To access Zymego's services, a medium-level identity verification
            using NHS login is required.
          </Trans>
        );
      case "high":
        return (
          <Trans>
            You are logged into Zymego, but to access all services, you must
            complete NHS login's highest level of identity verification.
          </Trans>
        );
    }
  }

  return (
    <Trans>
      To access Zymego's services, you need to sign in with an authorized
      account. Please check your login credentials and try again.
    </Trans>
  );
}

function AuthShieldLink(properties: { children: ReactNode; href: string }) {
  return (
    <Typography {...properties} component="a" sx={{ color: colors.red }} />
  );
}

function AuthShield({
  onBackClick,
  requiredAuthLevel,
  state,
  sx,
}: {
  onBackClick?: () => void;
  requiredAuthLevel: AuthLevel;
  state?: State;
  sx?: SxProps;
}) {
  const { AUTH_IDENTITY_PROVIDER } = getConfig();

  const signInButtonText =
    AUTH_IDENTITY_PROVIDER === "nhs"
      ? requiredAuthLevel === "low"
        ? t`Go back to NHS login`
        : t`Upgrade your NHS account`
      : t`Sign in`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        position: "relative",
        ...sx,
      }}
    >
      <Alert severity={requiredAuthLevel === "low" ? "error" : "info"}>
        <AuthShieldAlertBody requiredAuthLevel={requiredAuthLevel} />
      </Alert>
      <Button
        onClick={() => startSignIn({ authLevel: requiredAuthLevel, state })}
        size="medium"
        sx={{ margin: 0 }}
      >
        {signInButtonText}
      </Button>
      {onBackClick && (
        <Button
          design="white-bordered"
          onClick={onBackClick}
          sx={{ margin: 0 }}
        >
          <Trans>Back to start</Trans>
        </Button>
      )}
    </Box>
  );
}

export { AuthShield };
