import type { OidcMetadata } from "oidc-client-ts";

import { GEOGRAPHIES, Geography } from "./geographies";
import { DEFAULT_VECTORS_OF_TRUST } from "./nhs/identity-proofing-level";

type AuthUserStore = "inMemoryStorage" | "localStorage";

type Config = {
  ALLOW_GUARDIANSHIP: boolean;
  API_URL: string;
  AUTH_DISABLE_PKCE: boolean;
  AUTH_EXTRA_QUERY_PARAMS:
    | Record<string, string | number | boolean>
    | undefined;
  AUTH_IDENTITY_PROVIDER: IdentityProvider;
  AUTH_METADATA: Partial<OidcMetadata> | undefined;
  AUTH_PATIENT_CLIENT_ID: string;
  AUTH_SCOPE: string;
  AUTH_URL: string;
  AUTH_USER_STORE: AuthUserStore;
  DEPENDANT_MAX_AGE_EXCLUSIVE: 16 | 18;
  ELASTIC_APM_AGENT_ACTIVE: boolean;
  ELASTIC_APM_SERVER_URL: string | undefined;
  ENVIRONMENT: Environment;
  GEOGRAPHY: Geography;
  WEBSITE_URL: string;
};

type Environment = (typeof ENVIRONMENTS)[number];

type GetConfigOptions = {
  environment?: Record<string, string | undefined>;
  hostname?: string;
};

type IdentityProvider = "e-identitet" | "nhs" | "signicat";

const END_SESSION_URL = globalThis.location
  ? `${globalThis.location.origin}/logout/end-session`
  : undefined;
const ENVIRONMENTS = ["development", "production", "staging"] as const;
const LOGIN_URL = globalThis.location
  ? `${globalThis.location.origin}/login`
  : undefined;

function keys<T extends Record<PropertyKey, unknown>>(
  value: Record<keyof T, unknown>,
) {
  return Object.keys(value) as (keyof T)[];
}

const CONFIG_KEYS = keys<Config>({
  ALLOW_GUARDIANSHIP: true,
  API_URL: true,
  AUTH_DISABLE_PKCE: true,
  AUTH_EXTRA_QUERY_PARAMS: true,
  AUTH_IDENTITY_PROVIDER: true,
  AUTH_METADATA: true,
  AUTH_PATIENT_CLIENT_ID: true,
  AUTH_SCOPE: true,
  AUTH_URL: true,
  AUTH_USER_STORE: true,
  DEPENDANT_MAX_AGE_EXCLUSIVE: true,
  ELASTIC_APM_AGENT_ACTIVE: true,
  ELASTIC_APM_SERVER_URL: true,
  ENVIRONMENT: true,
  GEOGRAPHY: true,
  WEBSITE_URL: true,
});

function getConfig({
  environment: environmentVariables = import.meta.env,
  hostname = globalThis.location?.hostname ?? "localhost",
}: GetConfigOptions = {}): Config {
  const { environment, geography } = parseHostname({
    environmentGeography: environmentVariables.VITE_GEOGRAPHY,
    hostname,
  });

  const baseApiUrl =
    environment === "development"
      ? "https://localhost:3010"
      : `https://api.${hostname}`;

  const baseConfig = {
    API_URL: `${baseApiUrl}/patient/graphql`,
    AUTH_DISABLE_PKCE: false,
    AUTH_EXTRA_QUERY_PARAMS: undefined,
    AUTH_METADATA: undefined,
    DEPENDANT_MAX_AGE_EXCLUSIVE: 16,
    ELASTIC_APM_AGENT_ACTIVE: false,
    ELASTIC_APM_SERVER_URL: undefined,
  } satisfies Partial<Config>;

  const environmentConfigs = {
    development: {
      ENVIRONMENT: "development",
    },
    staging: {
      ELASTIC_APM_SERVER_URL: `${baseApiUrl}/apm`,
      ENVIRONMENT: "staging",
    },
    production: {
      ELASTIC_APM_SERVER_URL: `${baseApiUrl}/apm`,
      ENVIRONMENT: "production",
    },
  } satisfies Record<Environment, Partial<Config>>;

  const environmentGeographyConfigs = {
    developmentSE: {
      DEPENDANT_MAX_AGE_EXCLUSIVE: 18,
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    },
    stagingSE: {
      DEPENDANT_MAX_AGE_EXCLUSIVE: 18,
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    },
    productionSE: {
      WEBSITE_URL: "https://www.zymego.com/?r=0",
    },
    developmentUK: {
      WEBSITE_URL: "https://www.zymego.com/en",
    },
    stagingUK: {
      WEBSITE_URL: "https://zymego-2024-81fa4ae5dc0e88494a944110461.webflow.io",
    },
    productionUK: {
      WEBSITE_URL: "https://www.zymego.com/en",
    },
  } satisfies Record<`${Environment}${Geography}`, Partial<Config>>;

  const geographyConfigs = {
    SE: {
      ALLOW_GUARDIANSHIP: true,
      GEOGRAPHY: "SE",
    },
    UK: {
      ALLOW_GUARDIANSHIP: false,
      GEOGRAPHY: "UK",
    },
  } satisfies Record<Geography, Partial<Config>>;

  const identityProviderConfigs = {
    "e-identitet": {
      AUTH_IDENTITY_PROVIDER: "e-identitet",
      AUTH_PATIENT_CLIENT_ID: {
        development: "R99YV5kEbB",
        staging: "R99YV5kEbB",
        // cSpell:disable-next-line
        production: "7nkXhbknqB",
      }[environment],
      AUTH_SCOPE: "openid profile",
      AUTH_URL: {
        development: environmentVariables.VITE_USE_MOCK_AUTH
          ? "http://localhost:3100"
          : "https://oidc.test.grandid.com/v2",
        staging: "https://oidc.test.grandid.com/v2",
        production: "https://oidc.grandid.com/v2",
      }[environment],
      AUTH_USER_STORE: "localStorage",
    },
    nhs: {
      AUTH_DISABLE_PKCE: true,
      AUTH_EXTRA_QUERY_PARAMS: {
        vtr: JSON.stringify(DEFAULT_VECTORS_OF_TRUST),
      },
      AUTH_IDENTITY_PROVIDER: "nhs",
      AUTH_METADATA: {
        authorization_endpoint: `${baseApiUrl}/auth/authorize`,
        end_session_endpoint: END_SESSION_URL,
        token_endpoint: `${baseApiUrl}/auth/token?redirect_uri=${LOGIN_URL}`,
      },
      AUTH_PATIENT_CLIENT_ID: {
        development: "zymego-patient-portal-nhs-login-development",
        staging: "zymego-patient-portal-nhs-login-integration",
        production: "zymego-patient-portal-nhs-login-production",
      }[environment],
      AUTH_SCOPE: "openid profile email phone gp_registration_details",
      AUTH_URL: {
        development: "https://auth.sandpit.signin.nhs.uk",
        staging: "https://auth.aos.signin.nhs.uk",
        production: "https://auth.login.nhs.uk",
      }[environment],
      AUTH_USER_STORE: "localStorage",
    },
    signicat: {
      AUTH_IDENTITY_PROVIDER: "signicat",
      AUTH_PATIENT_CLIENT_ID: {
        development: "sandbox-gentle-cheese-220",
        staging: "sandbox-melancholy-eagle-485",
        production: "prod-petty-goat-588",
      }[environment],
      AUTH_SCOPE: "openid profile nin",
      AUTH_URL:
        environment === "development"
          ? "https://auth.local.zymego.app/auth/open"
          : `https://auth.${hostname}/auth/open`,
      AUTH_USER_STORE: "inMemoryStorage",
    },
  } satisfies Record<IdentityProvider, Partial<Config>>;

  const identityProviders = {
    SE: {
      development: "e-identitet" as const,
      staging: "e-identitet" as const,
      production: "e-identitet" as const,
    }[environment],
    UK: "nhs",
  } satisfies Record<Geography, IdentityProvider>;
  const identityProvider = identityProviders[geography];

  const config: Config = {
    ...baseConfig,
    ...environmentConfigs[environment],
    ...environmentGeographyConfigs[`${environment}${geography}`],
    ...geographyConfigs[geography],
    ...identityProviderConfigs[identityProvider],
  };

  // Allow overriding config variables with environment variables during development:
  if (environment === "development") {
    for (const key of CONFIG_KEYS) {
      const value = environmentVariables[`VITE_${key}`];

      if (value !== undefined) {
        if (key === "ELASTIC_APM_AGENT_ACTIVE") {
          config[key] = value === "true";
        } else {
          config[key] = value as never;
        }
      }
    }
  }

  return validateConfig(config);
}

function isDevelopmentHostname(hostname: string): boolean {
  const parts = hostname.split(".");
  const zymegoIndex = parts.indexOf("zymego");
  return zymegoIndex === -1 || parts[zymegoIndex - 1] === "local";
}

function isValidEnvironment(
  environment: string | undefined,
): environment is Environment {
  return ENVIRONMENTS.includes(environment as Environment);
}

function isValidGeography(
  geography: string | undefined,
): geography is Geography {
  return GEOGRAPHIES.includes(geography as Geography);
}

function parseHostname({
  environmentGeography,
  hostname,
}: {
  environmentGeography?: string;
  hostname: string;
}): { environment: Environment; geography: Geography } {
  const isDevelopment = isDevelopmentHostname(hostname);
  const parts = hostname.split(".");

  const environment = isDevelopment
    ? "development"
    : parts
        .map((part) => part.toLowerCase())
        .find((part) => isValidEnvironment(part)) ?? "production";

  const geography = isDevelopment
    ? isValidGeography(environmentGeography)
      ? environmentGeography
      : "SE"
    : parts
        .map((part) => part.toUpperCase())
        .find((part) => isValidGeography(part)) ?? "SE";

  return { environment, geography };
}

function validateConfig(config: Config) {
  for (const key of CONFIG_KEYS) {
    if (!(key in config)) {
      throw new ReferenceError(`Missing config variable: ${key}`);
    }
  }

  return config as Config;
}

export { getConfig, isDevelopmentHostname, parseHostname };
export type { Config, GetConfigOptions };
