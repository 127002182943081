import { t } from "@lingui/macro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { client } from "../apollo-client";
import { ErrorComponent } from "../error";
import { Layout } from "../layout";
import { Loading } from "../loading";
import { NewAppointmentLayout } from "../new-appointment-layout";
import { logError, newAppointmentFlowByLocation } from "../utils";
import { useGetValidateAndCreateGuardianSessionRequestQuery } from "./get-validate-and-create-guardian-session-request.graphql";

function AddGuardianshipStatus() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  invariant(id);

  const { data, error, loading } =
    useGetValidateAndCreateGuardianSessionRequestQuery({
      variables: {
        id,
      },
      pollInterval: 1000,
    });

  const requestInProgress =
    loading ||
    data?.validateAndCreateGuardianSessionRequest?.status === "QUEUED" ||
    data?.validateAndCreateGuardianSessionRequest?.status === "IN_PROGRESS";

  if (requestInProgress) {
    return newAppointmentFlowByLocation(location) ? (
      <NewAppointmentLayout>
        <Loading logo={false} text={t`Validating guardianship...`} />
      </NewAppointmentLayout>
    ) : (
      <Layout>
        <Loading logo={false} text={t`Validating guardianship...`} />
      </Layout>
    );
  }

  const requestErrored =
    error ||
    data?.validateAndCreateGuardianSessionRequest?.status === "FAILED" ||
    data?.validateAndCreateGuardianSessionRequest?.status === "UNSUCCESSFUL";

  if (requestErrored) {
    if (error) {
      logError(error);
    } else {
      logError("Guardian session validation and creation request failed.");
    }

    return newAppointmentFlowByLocation(location) ? (
      <NewAppointmentLayout>
        <ErrorComponent
          errorMessage={t`We could not verify that you are the child's guardian. This may be because your child's booking cannot be handled digitally, according to the clinic's rules or because your child does not have a booking at a clinic that uses Zymego.`}
          inline
        />
      </NewAppointmentLayout>
    ) : (
      <Layout>
        <ErrorComponent
          errorMessage={t`We could not verify that you are the child's guardian. This may be because your child's booking cannot be handled digitally, according to the clinic's rules or because your child does not have a booking at a clinic that uses Zymego.`}
          inline
        />
      </Layout>
    );
  }

  navigate({
    pathname: newAppointmentFlowByLocation(location)
      ? "/bookings/new/type-of-appointment"
      : "/",
    search: location.search,
  });

  client.resetStore();
  return null;
}

export { AddGuardianshipStatus };
