import { Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { useGetAppointmentQuery } from "../appointment-details/get-appointment.graphql";
import { useLeaveWaitlistMutation } from "../appointment-details/leave-waitlist.graphql";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";

function LeaveWaitlistDialog() {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();

  const { appointmentId } = useParams();

  invariant(appointmentId);

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const { data: { appointment } = {} } = useGetAppointmentQuery({
    variables: {
      id: appointmentId,
    },
  });

  const [leaveWaitlist, { loading: mutationLoading, error: mutationError }] =
    useLeaveWaitlistMutation();

  const leaveWaitlistHandler = async () => {
    try {
      await leaveWaitlist({
        variables: {
          appointmentId,
        },
      });

      trackEvent("leave-waitlist", {
        ...(appointment?.careUnit?.id
          ? {
              props: {
                careUnitId: appointment.careUnit.id,
                careUnitName: appointment.careUnit.name,
              },
            }
          : {}),
      });

      navigate("status", { state: { backgroundLocation } });
    } catch {
      // The Apollo error will be handled by the error boundary instead.
    }
  };

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingTop: {
            xs: 3,
            sm: 4,
          },
          padding: {
            xs: 2,
            sm: 3,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography align="center" variant="h4">
          <Trans>Want to stop getting offers of earlier appointments?</Trans>
        </Typography>
      </Box>
      <Grid
        container
        justifyContent="center"
        padding={2}
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <LoadingButton
          color="secondary"
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          fullWidth
          loading={mutationLoading}
          onClick={leaveWaitlistHandler}
          size="large"
          variant="contained"
        >
          <Trans>Yes</Trans>
        </LoadingButton>
        {mutationError ? (
          <FormHelperText error={true} filled={true}>
            <Trans>Something went wrong when leaving the waiting list</Trans>
          </FormHelperText>
        ) : null}
      </Grid>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          fullWidth
          onClick={() => navigate(-1)}
          size="large"
          variant="dialog"
        >
          <Box
            sx={{
              margin: "auto",
              display: "inline-flex",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <ArrowLeft fill={colors.red} />
            </Avatar>
            <Trans>No, back</Trans>
          </Box>
        </Button>
      </Box>
    </DialogBottom>
  );
}

export { LeaveWaitlistDialog };
