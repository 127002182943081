/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { t } from "@lingui/macro";
import { Box, Button, ToggleButton, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Control, Controller, UseFormGetValues } from "react-hook-form";

import { GetTimeslotsQuery } from "./new-appointment/get-timeslots.graphql";
import { colors } from "./theme";
import { GroupedTimeslots } from "./utils";

type CareUnitTheme = {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
};

type Timeslot = NonNullable<GetTimeslotsQuery["timeslots"]>[number];

function RescheduleTimeTable({
  careUnitThemes,
  formProperties,
  timeslots,
}: {
  careUnitThemes?: Map<string, CareUnitTheme>;
  formProperties: {
    control: Control<{ id: string }>;
    getValues: UseFormGetValues<{ id: string }>;
  };
  timeslots: Timeslot[];
}) {
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();

  useEffect(() => {
    if (selectedTimeslot && !timeslots?.includes(selectedTimeslot)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedTimeslot(undefined);
    }
  }, [selectedTimeslot, timeslots]);

  // Group timeslots so we can easily loop through them in the jsx

  const groupedTimeslots: GroupedTimeslots<Timeslot> = {};

  for (const timeslot of timeslots) {
    const date = new Date(timeslot.startAtInCareUnitsTimezone);
    const month = format(date, "y-M");
    const day = format(date, "d");
    const time = format(date, "k:mm");

    // Set months
    if (!groupedTimeslots[month]) {
      groupedTimeslots[month] = {
        items: {},
        date: date,
      };
    }
    // Set days
    if (!groupedTimeslots[month]!["items"][day]) {
      groupedTimeslots[month]!["items"][day] = {
        items: {},
        date: date,
      };
    }
    // Set times
    groupedTimeslots[month]!["items"][day]!["items"][time] = {
      timeslot,
      date: date,
    };
  }

  return (
    <Box sx={{ paddingBottom: "96px" }}>
      {Object.keys(groupedTimeslots).map((month) => (
        <Box
          key={month}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            overflowX: "visible",
            paddingBottom: "24px",
            position: "relative",
            ":not(:first-child)": {
              borderTop: `1px solid ${colors.grey}`,
              paddingTop: "24px",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              boxShadow: "0 0 16px 16px white",
              display: "flex",
              gap: "6px",
              position: "sticky",
              top: 0,
              zIndex: "1",
            }}
          >
            <Typography
              sx={{
                color: colors.zymegoGreen,
                fontSize: "22px",
                fontWeight: 700,
                ":first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {format(groupedTimeslots[month]!.date, "MMMM")}
            </Typography>
            <Typography
              sx={{
                color: colors.zymegoGreen,
                fontSize: "22px",
                fontWeight: 400,
                opacity: 0.5,
              }}
            >
              {format(groupedTimeslots[month]!.date, "y")}
            </Typography>
          </Box>
          {Object.keys(groupedTimeslots[month]!.items).map((day) => (
            <Box key={day} sx={{ display: "flex", textAlign: "start" }}>
              <Box
                sx={{
                  flexShrink: "0",
                  maxWidth: "72px",
                  minWidth: "44px",
                  width: "19%",
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.zymegoGreen,
                    fontSize: "15px",
                    fontWeight: 600,
                    lineHeight: 1.15,
                  }}
                >
                  {format(groupedTimeslots[month]!.items[day]!.date, "E")}
                </Typography>
                <Typography
                  sx={{
                    color: colors.zymegoGreen,
                    fontSize: "22px",
                    fontWeight: 700,
                    lineHeight: 1.15,
                  }}
                >
                  {format(groupedTimeslots[month]!.items[day]!.date, "d")}
                </Typography>
              </Box>
              <Controller
                control={formProperties.control}
                defaultValue={selectedTimeslot?.id}
                name="id"
                render={({ field }) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      {Object.keys(
                        groupedTimeslots[month]!.items[day]!.items,
                      ).map((timeslot) => {
                        const careUnitId =
                          groupedTimeslots[month]!.items[day]!.items[timeslot]!
                            .timeslot!.careUnit!.id;
                        const theme = careUnitThemes?.get(careUnitId);

                        return (
                          <ToggleButton
                            key={timeslot}
                            {...field}
                            component={Button}
                            selected={
                              formProperties.getValues("id") ===
                              groupedTimeslots[month]!.items[day]!.items[
                                timeslot
                              ]!.timeslot?.id
                            }
                            sx={{
                              ...(theme && {
                                backgroundColor: theme.backgroundColor,
                                borderColor: theme.backgroundColor,
                              }),
                              color: theme?.textColor,
                              fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: "22px",
                              margin: 0,
                              minWidth: "48px",
                              paddingY: "8px",
                              width: "calc(25% - 6px)",
                              "&.Mui-selected": {
                                ...(theme && {
                                  borderColor: theme.borderColor,
                                }),
                                background: colors.white,
                                "&:hover": {
                                  background: colors.white,
                                },
                              },
                              "&:hover": {
                                background: colors.white,
                              },
                              // Undo hover effect on touch devices:
                              "@media (hover: none)": {
                                "&:hover": {
                                  backgroundColor: "inherit",
                                },
                              },
                            }}
                            type="button"
                            value={
                              groupedTimeslots[month]!.items[day]!.items[
                                timeslot
                              ]!.timeslot.id
                            }
                          >
                            {format(
                              groupedTimeslots[month]!.items[day]!.items[
                                timeslot
                              ]!.date,
                              "k:mm",
                            )}
                          </ToggleButton>
                        );
                      })}
                    </Box>
                  );
                }}
                rules={{
                  required: t`Please select one of these options`,
                }}
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export { RescheduleTimeTable };
export type { CareUnitTheme };
