import { t, Trans } from "@lingui/macro";
import {
  Box,
  Button as MuiButton,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { Button } from "../components/button/button";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { Loading } from "../loading";
import { isAppointmentWaitlistable } from "../utils";
import { useGetRescheduleAppointmentStatusQuery } from "./fetch-reschedule-request-status.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

function RescheduleStatus() {
  const { formatDate, formatTime } = useDateFormatter();
  const { rescheduleAppointmentRequestId, appointmentId = "" } = useParams();

  invariant(rescheduleAppointmentRequestId, "requestId is missing from url");
  invariant(appointmentId, "timeslotId is missing from url");

  const location = useLocation();
  const navigate = useNavigate();

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    stopPolling,
  } = useGetRescheduleAppointmentStatusQuery({
    variables: {
      rescheduleAppointmentRequestId,
    },
    pollInterval: 200,
  });

  const {
    loading,
    error,
    data: { appointment } = {},
  } = useGetAppointmentQuery({
    variables: { id: appointmentId },
  });
  const resolvedPath = useResolvedPath(".");
  const match = useMatch({ path: resolvedPath.pathname, end: true });

  const navigateToJoinTimeoutHandler = useRef<ReturnType<typeof setTimeout>>();
  const [haveNavigatedToJoin, setHaveNavigatedToJoin] = useState(false);

  const navigateToFeedbackTimeoutHandler =
    useRef<ReturnType<typeof setTimeout>>();
  const [haveNavigatedToFeedback, setHaveNavigatedToFeedback] = useState(false);

  useEffect(() => {
    if (
      match &&
      !haveNavigatedToJoin &&
      statusData?.rescheduleAppointmentRequest?.status === "SUCCESS" &&
      appointment &&
      isAppointmentWaitlistable(appointment)
    ) {
      navigateToJoinTimeoutHandler.current = setTimeout(() => {
        setHaveNavigatedToJoin(true);
        navigate("join", {
          state: { backgroundLocation: location },
        });
      }, 1000);
    }

    return () => {
      if (navigateToJoinTimeoutHandler.current) {
        clearTimeout(navigateToJoinTimeoutHandler.current);
      }
    };
  }, [
    navigate,
    location,
    statusData,
    appointment,
    match,
    haveNavigatedToJoin,
    setHaveNavigatedToJoin,
  ]);

  useEffect(() => {
    if (
      match &&
      !haveNavigatedToFeedback &&
      statusData?.rescheduleAppointmentRequest?.status === "SUCCESS" &&
      (statusData?.rescheduleAppointmentRequest?.fromWaitlist ||
        statusData?.rescheduleAppointmentRequest?.fromWishlist)
    ) {
      navigateToFeedbackTimeoutHandler.current = setTimeout(() => {
        setHaveNavigatedToFeedback(true);
        navigate("feedback", {
          state: { backgroundLocation: location },
        });
      }, 1000);
    }

    return () => {
      if (navigateToFeedbackTimeoutHandler.current) {
        clearTimeout(navigateToFeedbackTimeoutHandler.current);
      }
    };
  }, [
    navigate,
    location,
    statusData,
    appointment,
    // careUnitHaveWaitlistEnabled,
    // careUnitHaveWishlistEnabled,
    match,
    haveNavigatedToFeedback,
    setHaveNavigatedToFeedback,
  ]);

  useEffect(() => {
    if (
      statusData?.rescheduleAppointmentRequest &&
      ["SUCCESS", "FAILED"].includes(
        statusData.rescheduleAppointmentRequest.status,
      )
    ) {
      stopPolling();
    }
  }, [stopPolling, statusData]);

  useEffect(() => {
    if (
      statusData &&
      statusData.rescheduleAppointmentRequest?.status === "SUCCESS"
    ) {
      trackEvent("reschedule-appointment", {
        props: {
          careUnitId: statusData.rescheduleAppointmentRequest.careUnitId,
          careUnitName:
            statusData.rescheduleAppointmentRequest.careUnit?.name ?? "",
        },
      });
    }
  }, [statusData]);

  if (error ?? statusError) {
    throw error ?? statusError;
  }

  if (statusData?.rescheduleAppointmentRequest?.status === "FAILED") {
    throw new Error("Appointment rescheduling request failed.");
  }

  if (
    loading ||
    statusData?.rescheduleAppointmentRequest?.status === "IN_PROGRESS"
  ) {
    return (
      <Box>
        <Loading logo={false} text={t`Rescheduling appointment`} />
      </Box>
    );
  }

  return (
    <Grid
      alignItems="stretch"
      container
      direction="column"
      flexGrow={1}
      spacing={2}
      sx={{
        padding: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Grid item>
        <Card
          sx={{ border: "2px solid rgba(0 ,0 ,0 ,0.2)", borderRadius: "13px" }}
        >
          <Box sx={{ padding: { xs: 2, sm: 3 }, alignItems: "center" }}>
            <Typography sx={{ fontWeight: 500 }} variant="h5">
              <Trans>You have successfully rescheduled!</Trans>
            </Typography>
          </Box>
          {appointment ? (
            <Box
              sx={{
                paddingX: { xs: 2, sm: 3 },
                paddingY: 1.5,
                borderTop: "2px solid rgba(0 ,0 ,0 ,0.1)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                data-cy="appointment-reschedule-status-date"
                sx={{
                  fontWeight: 500,
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
                variant="h2"
              >
                {formatDate(new Date(appointment?.startAtInCareUnitsTimezone))}
              </Typography>
              <Box sx={{ display: "block", textAlign: "right" }}>
                <Typography
                  color="secondary"
                  data-cy="appointment-reschedule-status-distance-to-now"
                  sx={{ fontWeight: 600 }}
                  variant="subtitle2"
                >
                  {formatDistanceToNow(
                    new Date(appointment?.startAtInCareUnitsTimezone),
                    {
                      addSuffix: true,
                    },
                  )}
                </Typography>
                <Typography
                  data-cy="appointment-reschedule-status-time"
                  variant="h6"
                >
                  {formatTime(
                    new Date(appointment?.startAtInCareUnitsTimezone),
                  )}
                </Typography>
              </Box>
            </Box>
          ) : undefined}
          {appointment ? (
            <Stack
              spacing={0.5}
              sx={{
                padding: { xs: 2, sm: 3 },
                borderTop: "2px solid rgba(0 ,0 ,0 ,0.1)",
              }}
            >
              <Typography
                data-cy="appointment-reschedule-status-care-unit"
                sx={{ fontWeight: 500 }}
                variant="h4"
              >
                {appointment?.careUnit?.name}
              </Typography>
              <Typography
                data-cy="appointment-reschedule-status-caregiver"
                variant="boldSubtitle1"
              >
                <Trans>Appointment with {appointment?.caregiver?.name}</Trans>
              </Typography>
              <Box
                data-cy="appointment-reschedule-status-address"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="body1">{`${appointment.careUnit?.address}, ${appointment.careUnit?.postAddress} ${appointment.careUnit?.postCode}`}</Typography>
              </Box>
            </Stack>
          ) : undefined}
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderTop: "2px solid rgba(0,0,0,0.1)",
            padding: { sx: 1, sm: 2 },
            alignItems: "center",
          }}
        >
          <Avatar sx={{ color: colors.red, background: colors.zymegoRose }}>
            <CalendarTodayIcon />
          </Avatar>

          <Typography variant="h6" marginLeft={3}>
            Add to calendar
          </Typography>
        </Box> */}
        </Card>
      </Grid>
      <Grid item paddingBottom={3}>
        <MuiButton
          color="secondary"
          component={Link}
          disabled={loading || statusLoading}
          fullWidth
          sx={{ marginTop: 0 }}
          to="/"
          variant="contained"
        >
          <Trans>My Appointments</Trans>
        </MuiButton>
        <Button design="transparent" href="/logout" icon="logout">
          <Trans>Sign out</Trans>
        </Button>
      </Grid>
    </Grid>
  );
}
export { RescheduleStatus };
