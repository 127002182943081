import { t, Trans } from "@lingui/macro";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { Loading } from "../loading";
import { formatPatientIdentifier } from "../patient/identifier";
import { colors, theme } from "../theme";
import { useGetProfileSelectorDataQuery } from "./get-profile-selector-data.graphql";
import { useSwitchSessionMutation } from "./switch-session.graphql";

function ProfilesList() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, error, loading } = useGetProfileSelectorDataQuery();

  const [
    switchSession,
    { data: switchSessionData, error: switchSessionError },
  ] = useSwitchSessionMutation();

  if (switchSessionData) {
    navigate(
      `/switch-session/${switchSessionData?.switchSession?.switchSessionRequest?.id}/status`,
    );
  }

  if (loading) {
    return <Loading text={t`Loading profiles...`} />;
  }

  if (error ?? switchSessionError) {
    throw error ?? switchSessionError;
  }

  if (!data) {
    throw new ReferenceError("Query did not return any data.");
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="stretch"
      spacing={{
        xs: 2,
        sm: 2,
      }}
      sx={{
        paddingX: {
          xs: 2,
          sm: 3,
        },
        paddingY: {
          xs: 3,
          sm: 4,
        },
      }}
    >
      <Grid item>
        <Card>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              padding: {
                xs: "12px 16px",
                sm: 3,
              },
              textAlign: "center",
            }}
          >
            <Typography marginBottom={1} variant="h5">
              <Trans>Select profile</Trans>
            </Typography>
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                switchSession({
                  variables: {
                    patientId: data.guardian?.id ?? data.patient?.id ?? "",
                  },
                });
              }}
              variant="contained"
            >
              <Trans>My profile</Trans>
            </Button>
            {data.guardianships?.map((guardianship) => {
              if (!guardianship.dependantPatient) {
                return null;
              }

              const patientId = guardianship.dependantPatient.id;

              const identifier = formatPatientIdentifier(
                guardianship.dependantPatient.identifier,
              );

              return (
                <Button
                  fullWidth
                  key={guardianship.id}
                  onClick={() => {
                    switchSession({
                      variables: {
                        patientId,
                      },
                    });
                  }}
                  sx={{ backgroundColor: colors.zymegoRose, color: "black" }}
                  variant="contained"
                >
                  {identifier}
                </Button>
              );
            })}
            <Button
              color="primary"
              endIcon={
                theme.direction === "ltr" ? <ArrowRight /> : <ArrowLeft />
              }
              fullWidth
              onClick={() => {
                navigate("/add-guardianship", {
                  state: { backgroundLocation: location },
                });
              }}
              variant="outlined"
            >
              <Trans>Add another child</Trans>
            </Button>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
export { ProfilesList };
