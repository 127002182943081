import { t, Trans } from "@lingui/macro";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { isLateAppointmentChange } from "../appointment/appointment-utils";
import { Radio } from "../components/radio/radio";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useGetAppointmentQuery } from "./get-appointment.graphql";

type CancellationConfirmationInWishlistFormValues = {
  cancellationRequestType: string;
};

type CancellationConfirmationInWishlistParameters = {
  appointmentId: string;
};

// Manage cancellation dialog
function CancellationConfirmationInWishlist() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDateTime } = useDateFormatter();
  const { appointmentId: id = "" } =
    useParams<CancellationConfirmationInWishlistParameters>();

  const {
    loading,
    error,
    data: { appointment } = {},
  } = useGetAppointmentQuery({ variables: { id } });

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CancellationConfirmationInWishlistFormValues>({
    defaultValues: {
      cancellationRequestType: appointment?.canCancel ? "" : "false",
    },
  });

  const cancellationRequestTypeField = watch("cancellationRequestType");

  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const navigate = useNavigate();

  if (error) {
    throw error;
  }

  if (!loading && !appointment) {
    throw new ReferenceError("Query did not return an appointment.");
  }

  const onSubmit: SubmitHandler<
    CancellationConfirmationInWishlistFormValues
  > = (values: CancellationConfirmationInWishlistFormValues) => {
    if (values.cancellationRequestType === "false") {
      navigate("../status", { replace: true });
    } else {
      navigate(`../contact-info/${values.cancellationRequestType}`, {
        state: { backgroundLocation },
      });
    }
  };

  const isLateCancellation = isLateAppointmentChange(appointment);

  return (
    <DialogBottom
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              paddingBottom: {
                xs: 1,
                sm: 2,
              },
              paddingX: {
                xs: 2,
                sm: 3,
              },
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: "8px",
            }}
          >
            <Avatar
              sx={{
                width: "27px",
                height: "27px",
                color: colors.white,
                backgroundColor: colors.zymegoWarmGreen,
              }}
            >
              <CheckIcon sx={{ fontSize: 20 }} />
            </Avatar>

            <Typography align="center" variant="h4">
              <Trans>Your request has been saved!</Trans>
            </Typography>
          </Box>
          {appointment?.canCancel && (
            <Box
              sx={{
                paddingX: {
                  xs: 1,
                  sm: 2,
                },
              }}
            >
              <Typography align="center" variant="h4">
                <Trans>Do you want to keep your current appointment?</Trans>
              </Typography>
            </Box>
          )}
          {appointment?.canCancel && (
            <>
              <Box
                sx={{
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    color: colors.zymegoWarmGreen,
                    ":first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  variant="h4"
                >
                  {formatDateTime(
                    new Date(appointment.startAtInCareUnitsTimezone),
                  )}
                </Typography>
              </Box>
              <FormControl
                error={Boolean(errors.cancellationRequestType?.message)}
                sx={{
                  border: "2px solid #E6E6E6",
                  borderRadius: "6px",
                  padding: "1rem 2rem",
                  width: "100%",
                }}
              >
                <Controller
                  control={control}
                  defaultValue=""
                  name="cancellationRequestType"
                  render={({ field }) => (
                    <Radio.Group {...field} aria-label="Cancellation">
                      <Radio
                        data-cy="cancellation-confirmation-yes"
                        label={t`YES`}
                        value="false"
                      />
                      <Radio
                        data-cy="cancellation-confirmation-no"
                        label={t`NO`}
                        value="true"
                      />
                    </Radio.Group>
                  )}
                  rules={{ required: t`Selection is required.` }}
                />
                <FormHelperText
                  error={Boolean(errors.cancellationRequestType?.message)}
                >
                  {errors.cancellationRequestType?.message}
                </FormHelperText>
              </FormControl>
            </>
          )}

          {cancellationRequestTypeField === "" ? (
            isLateCancellation ? (
              <Box sx={{ padding: { sm: 2, xs: 1 } }}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "7px",
                    backgroundColor: colors.zymegoPowder,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                    gap: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: colors.zymegoRose,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>

                  <Typography textAlign="left" variant="h6">
                    <Trans>
                      If you cancel or do not attend this appointment, you will
                      be charged by the clinic.
                    </Trans>
                  </Typography>
                </Box>
              </Box>
            ) : (
              appointment?.canCancel && (
                <Box
                  sx={{
                    padding: {
                      xs: 2,
                      sm: 3,
                    },
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    opacity: "60%",
                    height: "100px",
                  }}
                >
                  <Typography
                    align="center"
                    color={colors.zymegoDarkGreen}
                    variant="h6"
                  >
                    <Trans>
                      Choose whether you want to keep or cancel your current
                      appointment to proceed.
                    </Trans>
                  </Typography>
                </Box>
              )
            )
          ) : cancellationRequestTypeField === "true" ? (
            isLateCancellation ? (
              <Box sx={{ padding: { sm: 2, xs: 1 } }}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "7px",
                    backgroundColor: colors.zymegoPowder,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                    gap: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: colors.zymegoRose,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>

                  <Typography textAlign="left" variant="h6">
                    <Trans>
                      We'll cancel your current appointment and the clinic will
                      charge you for a late cancellation.
                    </Trans>
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                  height: "100px",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    borderRadius: "7px",

                    backgroundColor: colors.zymegoPowder,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                    gap: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: colors.zymegoRose,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>

                  <Typography textAlign="left" variant="h6">
                    <Trans>We'll cancel your current appointment. </Trans>
                  </Typography>
                </Box>
              </Box>
            )
          ) : isLateCancellation ? (
            <Box sx={{ padding: { sm: 2, xs: 1 } }}>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "7px",
                  backgroundColor: colors.zymegoPowder,
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                  gap: "8px",
                }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: colors.zymegoRose,
                    color: colors.zymegoDarkGreen,
                    fontSize: "large",
                  }}
                >
                  <PriorityHighIcon sx={{ fontSize: 20 }} />
                </Avatar>

                <Typography textAlign="left" variant="h6">
                  <Trans>
                    If you don't plan to attend this appointment, please cancel
                    by choosing "No".
                  </Trans>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                padding: {
                  xs: 2,
                  sm: 3,
                },
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                opacity: "60%",
                height: "100px",
              }}
            >
              <Typography
                align="center"
                color={colors.zymegoDarkGreen}
                variant="h6"
              >
                <Trans>
                  You'll keep your current appointment and get offers of time
                  slots according to your request.
                </Trans>
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              margin: {
                xs: 2,
                sm: 3,
              },
            }}
          >
            <Button
              data-cy="cancellation-confirmation-next-button"
              disabled={cancellationRequestTypeField === ""}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              <Trans>Next</Trans>
            </Button>
          </Box>
        </form>
      </Box>
    </DialogBottom>
  );
}
export { CancellationConfirmationInWishlist };
