import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { SvgIconProps } from "@mui/material";

import { ReactComponent as ArrowBottom } from "../../icons/arrow-bottom.svg";
import { ReactComponent as ArrowLeft } from "../../icons/arrow-left-large.svg";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";
import { ReactComponent as DeviceIcon } from "../../icons/device.svg";
import { ReactComponent as EnvelopeIcon } from "../../icons/envelope.svg";
import { theme } from "../../theme";

type IconName = keyof typeof icons;

type IconProperties = SvgIconProps & {
  name: IconName;
  size?: IconSize;
  fill?: IconColor;
  stroke?: IconColor;
};

type IconSize = "inherit" | "lg" | "md" | "sm";
type IconColor = "primary" | "secondary" | "currentcolor";

const customIcons = {
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowBottom: ArrowBottom,
  bell: BellIcon,
  device: DeviceIcon,
  envelope: EnvelopeIcon,
};

const muiIcons = {
  accessTime: AccessTimeIcon,
  add: AddIcon,
  cancelPresentation: CancelPresentationIcon,
  checkCircleOutline: CheckCircleOutlineIcon,
  check: CheckIcon,
  chevronLeft: ChevronLeftIcon,
  clear: ClearIcon,
  close: CloseIcon,
  contentCopy: ContentCopyIcon,
  contentCopyOutlined: ContentCopyOutlinedIcon,
  dashboard: DashboardIcon,
  logout: LogoutIcon,
  menu: MenuIcon,
  moreTime: MoreTimeIcon,
  phoneOutlined: PhoneOutlinedIcon,
  priorityHigh: PriorityHighIcon,
  schedule: ScheduleIcon,
};

const icons = { ...customIcons, ...muiIcons };

const getFontSize = (size: IconSize) => {
  const fontSizes = {
    inherit: "inherit",
    lg: "large",
    md: "medium",
    sm: "small",
  } as const;
  return fontSizes[size];
};

const getColorFill = (color: IconColor) => {
  const colors = {
    primary: theme.palette.primary.main.toString(),
    secondary: theme.palette.secondary.main.toString(),
    currentcolor: "currentcolor",
  };
  return colors[color];
};

function Icon({
  fill = "currentcolor",
  name,
  stroke = "currentcolor",
  size = "md",
  ...iconProperties
}: IconProperties) {
  const IconElement = icons[name];

  return (
    <IconElement
      {...iconProperties}
      style={{
        fill: getColorFill(fill),
        fontSize: getFontSize(size),
        stroke: getColorFill(stroke),
      }}
    />
  );
}

export type { IconName };
export { Icon };
