import "../apm";
import "../mui-tel-input.css";

import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { Analytics } from "../analytics";
import { client } from "../apollo-client";
import { ErrorComponentSimple } from "../error";
import { LocalizedApp } from "../localized-app";
import { reportWebVitals } from "../report-web-vitals";
import { State } from "../router/state";
import { ThemeComponent as Theme } from "../theme";
import { isPublicPathname } from "../urls";
import { AppUser } from "../user/app-user";
import { logError } from "../utils";
import { AppSelector } from "./app-selector";
import { PublicApp } from "./public-app";

function fallbackRender({ error }: { error: unknown }) {
  return (
    <ErrorComponentSimple
      caughtError={
        error instanceof Error || typeof error === "string" ? error : undefined
      }
    />
  );
}

export function initApp({
  state,
  user,
}: {
  state?: State;
  user: AppUser | undefined;
}) {
  const selector = "#root";
  const container = document.querySelector(selector);

  if (!container) {
    throw new ReferenceError(
      `Selector '${selector}' did not match any element.`,
    );
  }

  const root = createRoot(container);
  const isPublicApp = isPublicPathname(globalThis.location.pathname);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <LocalizedApp>
          <HelmetProvider>
            <Theme>
              <CssBaseline />
              {isPublicApp ? (
                <PublicApp />
              ) : (
                <ApolloProvider client={client}>
                  <ErrorBoundary
                    FallbackComponent={fallbackRender}
                    onError={logError}
                  >
                    <Analytics />
                    <AppSelector state={state} user={user} />
                  </ErrorBoundary>
                </ApolloProvider>
              )}
            </Theme>
          </HelmetProvider>
        </LocalizedApp>
      </BrowserRouter>
    </StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
