import {
  InMemoryWebStorage,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";

import { getConfig } from "./runtime-config";

const {
  AUTH_DISABLE_PKCE,
  AUTH_EXTRA_QUERY_PARAMS,
  AUTH_METADATA,
  AUTH_PATIENT_CLIENT_ID,
  AUTH_SCOPE,
  AUTH_URL,
  AUTH_USER_STORE,
} = getConfig();

const { origin } = globalThis.location;

const userManager = new UserManager({
  authority: AUTH_URL,
  client_id: AUTH_PATIENT_CLIENT_ID,
  disablePKCE: AUTH_DISABLE_PKCE,
  extraQueryParams: AUTH_EXTRA_QUERY_PARAMS,
  redirect_uri: `${origin}/login`,
  response_type: "code",
  scope: AUTH_SCOPE,
  accessTokenExpiringNotificationTimeInSeconds: 3,
  silent_redirect_uri: `${origin}/login/silent`,
  userStore: new WebStorageStateStore({
    store:
      AUTH_USER_STORE === "localStorage"
        ? window.localStorage
        : new InMemoryWebStorage(),
  }),
  post_logout_redirect_uri: `${origin}/logout/callback`,
  redirectMethod: "replace",
  ...(AUTH_METADATA ? { metadata: AUTH_METADATA } : {}),
});

// TODO: Listen for "userUnloaded" or other relevant event and redirect to login page directly instead of logout to landing page.
// This has to be coordinated with the retry and/or logoutLink in apollo client setup that do the logout if 401 is received.

export { userManager };
