import { Trans } from "@lingui/macro";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useGetAppointmentQuery } from "../appointment-details/get-appointment.graphql";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { DialogBottom } from "../dialog-bottom";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";

function AskWaitlistDialogInReschedule() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDateTime } = useDateFormatter();
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  invariant(appointmentId);

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const {
    data: { appointment } = {},
    error,
    loading,
  } = useGetAppointmentQuery({
    variables: { id: appointmentId },
  });

  if (error) {
    throw error;
  }

  if (!loading && !appointment) {
    throw new ReferenceError("Query did not return an appointment.");
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          color={colors.blue}
          onClick={() => setIsDialogOpen(false)}
          underline="none"
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: 1,
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography align="center" variant="h4">
          <Trans>You have successfully rescheduled!</Trans>
        </Typography>
        {appointment ? (
          <Typography
            align="center"
            color="secondary"
            data-cy="appointment-reschedule-success-time"
            margin={1}
            sx={{
              ":first-letter": {
                textTransform: "uppercase",
              },
            }}
            variant="h5"
          >
            {formatDateTime(new Date(appointment.startAtInCareUnitsTimezone))}
          </Typography>
        ) : undefined}
      </Box>
      <Box
        sx={{
          padding: 1,
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          align="center"
          sx={{
            padding: "0 24px 8px 24px",
          }}
          variant="h6"
        >
          <Trans>
            Would you be interested if an earlier time slot becomes available?
          </Trans>
        </Typography>
        <Typography
          align="center"
          fontSize="15px"
          fontWeight="700"
          sx={{ opacity: "60%", color: colors.zymegoDarkGreen }}
        >
          <Trans>We can send offers of earlier time slots</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          color="primary"
          component={Link}
          fullWidth
          size="large"
          state={{ backgroundLocation }}
          sx={{
            fontSize: "22px",
            fontWeight: "700",
          }}
          to="../../../../waitlist/join"
          variant="contained"
        >
          <Trans>Yes, please</Trans>
        </Button>
      </Box>
      <Box>
        <Button
          data-cy="appointment-reschedule-success-close-button"
          fullWidth
          onClick={handleDialogClose}
          size="large"
          variant="dialog"
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                color: colors.red,
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <CloseIcon />
            </Avatar>
            <Trans>No, thank you</Trans>
          </Box>
        </Button>
      </Box>
    </DialogBottom>
  );
}

export { AskWaitlistDialogInReschedule };
