import { ApolloError } from "@apollo/client";
import { t } from "@lingui/macro";

import { isValidDate } from "../utils";
import {
  GetCareUnitsQuery,
  useGetCareUnitsQuery,
} from "./get-care-units.graphql";
import {
  GetTimeslotsQuery,
  useGetTimeslotsQuery,
} from "./get-timeslots.graphql";

type ErrorResult = {
  careUnits?: undefined;
  error: ApolloError | Error;
  errorMessage?: { header: string; message: string } | undefined;
  status: "error";
  timeslots?: undefined;
};

type LoadingResult = {
  careUnits?: undefined;
  status: "loading";
  timeslots?: undefined;
};

type Result = ErrorResult | LoadingResult | SuccessResult;

type SuccessResult = {
  careUnits: Exclude<GetCareUnitsQuery["careUnits"], null>;
  status: "success";
  timeslots: Exclude<GetTimeslotsQuery["timeslots"], null>;
};

export const useCareUnitsAndTimeslots = ({
  skip,
  variables: {
    bookingTypeIds,
    caregiverIds,
    careUnitUrlFriendlyNames,
    earliestStartAt,
    fetchTimeslotRequestId,
    latestEndAt,
  },
}: {
  skip?: boolean;
  variables: {
    bookingTypeIds: string[];
    caregiverIds?: string[];
    careUnitUrlFriendlyNames: string[];
    earliestStartAt: Date | undefined;
    fetchTimeslotRequestId: string;
    latestEndAt: Date | undefined;
  };
}): Result => {
  const { data: { careUnits } = {}, error: careUnitsError } =
    useGetCareUnitsQuery({
      fetchPolicy: "network-only",
      variables: { careUnitUrlFriendlyNames },
    });

  const careUnitIds = careUnits?.map((careUnit) => careUnit.id);
  const isEarliestStartAtInvalid =
    Boolean(earliestStartAt) && !isValidDate(earliestStartAt);
  const isLatestEndAtInvalid =
    Boolean(latestEndAt) && !isValidDate(latestEndAt);

  const {
    data: { fetchTimeslotsRequests, timeslots } = {},
    error: timeslotsError,
  } = useGetTimeslotsQuery({
    fetchPolicy: "network-only",
    pollInterval: 5 * 1000,
    skip:
      skip || !careUnitIds || isEarliestStartAtInvalid || isLatestEndAtInvalid,
    variables: {
      bookingTypeIds,
      caregiverIds,
      careUnitIds: careUnitIds ?? [],
      earliestStartAt: isValidDate(earliestStartAt)
        ? earliestStartAt.toISOString()
        : undefined,
      fetchTimeslotRequestId,
      latestEndAt: isValidDate(latestEndAt)
        ? latestEndAt.toISOString()
        : undefined,
    },
  });

  if (isEarliestStartAtInvalid) {
    return {
      error: new Error("Invalid earliest start date"),
      errorMessage: {
        header: t`Invalid earliest start date`,
        message: t`The provided earliest start date is invalid. Please check the url and update the date format of the earliest start date.`,
      },
      status: "error",
    };
  }

  if (isLatestEndAtInvalid) {
    return {
      error: new Error("Invalid latest end date"),
      errorMessage: {
        header: t`Invalid latest end date`,
        message: t`The provided latest end date is invalid. Please check the url and update the date format of the latest end date.`,
      },
      status: "error",
    };
  }

  if (careUnitsError) {
    return { error: careUnitsError, status: "error" };
  }

  if (timeslotsError) {
    return { error: timeslotsError, status: "error" };
  }

  if (fetchTimeslotsRequests?.some((request) => request.status === "FAILED")) {
    return {
      error: new Error("One or more fetch timeslots requests failed."),
      status: "error",
    };
  }

  if (
    !careUnits ||
    !timeslots ||
    !fetchTimeslotsRequests ||
    fetchTimeslotsRequests.some(
      (request) =>
        request.status === "QUEUED" || request.status === "IN_PROGRESS",
    )
  ) {
    return { status: "loading" };
  }

  return { careUnits, status: "success", timeslots };
};
