import { Box } from "@mui/material";

function DrawerHandle() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        left: 0,
        pointerEvents: "none",
        position: "absolute",
        right: 0,
        top: "16px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "black",
          borderRadius: 999,
          height: "4px",
          opacity: 0.09,
          width: "96px",
        }}
      />
    </Box>
  );
}

export { DrawerHandle };
