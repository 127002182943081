import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";

import { ErrorComponent } from "./error";
import { useGetBookingTypesQuery } from "./get-booking-types.graphql";
import { Layout } from "./layout";
import { logError, splitByComma } from "./utils";

function NewAppointmentLayout({
  children,
  headerOnBackClick = false,
}: PropsWithChildren<
  BoxProps & {
    headerOnBackClick?: boolean;
    showBookingTypeIfAvailable?: boolean;
  }
>) {
  const [searchParameters] = useSearchParams();

  const bookingTypeIds = splitByComma(searchParameters.get("bookingTypeId"));

  const {
    error: bookingTypeError,
    loading: bookingTypeLoading,
    data: { bookingTypes } = {},
  } = useGetBookingTypesQuery({
    variables: { bookingTypeIds: bookingTypeIds },
    skip: !bookingTypeIds,
  });

  if (!bookingTypes && bookingTypeLoading) {
    return null;
  }

  if (bookingTypeError) {
    logError(bookingTypeError);
    return (
      <Layout headerColor="light">
        <ErrorComponent component="new-booking-error" />
      </Layout>
    );
  }

  return (
    <Layout headerColor="light" headerOnBackClick={headerOnBackClick}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        {children}
      </Box>
    </Layout>
  );
}

export { NewAppointmentLayout };
