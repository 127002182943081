import { Navigate, To, useLocation } from "react-router-dom";

import { isLocation } from "../router/state";
import { staticUrls } from "../urls";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { getNextOnboardingStep } from "./onboarding-view";

function Onboarding() {
  const { data, error, loading } = useGetCurrentPatientQuery();
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (error) {
    throw error;
  }

  if (!data?.patient) {
    throw new ReferenceError("Query did not return a patient.");
  }

  const nextOnboardingStep = getNextOnboardingStep(data.patient);
  const state = isLocation(location.state) ? location.state : undefined;
  const to: To = nextOnboardingStep
    ? staticUrls.onboarding[nextOnboardingStep]
    : state ?? staticUrls.index;

  return <Navigate replace state={state} to={to} />;
}

export { Onboarding };
