import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  SxProps,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { ProfileSelector } from "./guardianship/profile-selector";
import { ReactComponent as ArrowLeftLarge } from "./icons/arrow-left-large.svg";
import { ReactComponent as ZymegoLogo } from "./icons/zymego-logo.svg";
import { LanguageSelector } from "./localized-app";
import { colors, theme } from "./theme";

function Header({
  color = "dark",
  sticky = false,
  hideLogo = false,
  hideContact = false,
  onBackClick,
  onAddGuardianDependantClick,
  onSwitchProfileClick,
  sx,
}: {
  color?: "dark" | "light";
  sticky?: boolean;
  hideLogo?: boolean;
  hideContact?: boolean;
  sx?: SxProps;
  onBackClick?: () => void;
  onAddGuardianDependantClick?: () => void;
  onSwitchProfileClick?: () => void;
}) {
  const HeaderWrapper = styled(Box)`
    width: 100%;
    position: ${sticky === true ? "sticky" : "relative"};
    z-index: 10;
    top: 0;
    left: 0;
    padding: 12px 16px 24px 16px;
    &:after {
      content: " ";
      background-color: ${color === "light"
        ? colors.white
        : colors.zymegoGreen};
      position: absolute;
      bottom: 0;
      left: -25%;
      right: -25%;
      width: 150%;
      height: 120px;
      z-index: 0;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }
    ${theme.breakpoints.up("sm")} {
      padding: 16px 24px 32px 24px;
    }
    min-height: 94px;
  `;

  const HeaderTop = styled(Box)`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 1;
  `;

  const HeaderTopColumnLeft = styled(Box)`
    width: 20%;
    text-align: left;
  `;

  const HeaderTopColumnRight = styled(Box)`
    margin-top: 6px;
    width: 20%;
  `;

  const BackButton = styled(IconButton)`
    appearance: none;
    background-color: ${color === "light"
      ? "rgba(221, 243, 222, 1)"
      : "rgba(221, 243, 222, 0.35)"};
    border: none;
    cursor: pointer;
    box-shadow: none;
    border-radius: 100%;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    &:active,
    &:hover {
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
      background-color: ${color === "light"
        ? "rgba(221, 243, 222, 0.35)"
        : "rgba(221, 243, 222, 0.50)"};
    }
  `;

  const location = useLocation();

  const TopRightFixedBox = styled(Box)`
    display: block;
    width: calc(100% + 24px);
    z-index: 2;
    position: relative;
    padding: 0;
    @media (max-width: 480px) {
      right: 0;
      top: 10%;
      position: fixed;
    }
  `;

  const AddChildButton = styled(Button)`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    border: 2px solid;
    padding: 1px 6px;
    border-radius: 12px;
    color: ${colors.zymegoDarkGreen};
    border-color: ${colors.zymegoSand};
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    &:hover {
      border-color: ${colors.zymegoSand};
      border: 2px solid;
      background-color: ${colors.white};
    }
    text-transform: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    font-family: "Gellix";
    line-hight: 16px;
  `;

  return (
    <HeaderWrapper
      component="header"
      sx={{
        ...sx,
      }}
    >
      <HeaderTop sx={{ direction: "ltr", alignItems: "flex-start" }}>
        <HeaderTopColumnLeft>
          {onBackClick ? (
            <BackButton onClick={() => onBackClick()}>
              <ArrowLeftLarge
                style={{
                  fill: color === "light" ? colors.zymegoGreen : colors.white,
                  fontSize: "24px",
                }}
              />
            </BackButton>
          ) : null}
        </HeaderTopColumnLeft>
        <Grid>
          <Grid container item justifyContent="center">
            {hideLogo === false ? (
              <RouterLink
                style={{ alignItems: "center", display: "flex" }}
                to="/"
              >
                <ZymegoLogo
                  style={{
                    fill: color === "light" ? colors.zymegoGreen : colors.white,
                    marginTop: "8px",
                  }}
                />
              </RouterLink>
            ) : null}
          </Grid>
          <Grid alignContent="center" container item justifyContent="center">
            {onSwitchProfileClick ? <ProfileSelector /> : null}
            {onAddGuardianDependantClick ? (
              <AddChildButton
                onClick={onAddGuardianDependantClick}
                sx={{ marginTop: 1, marginBottom: -2 }}
              >
                <Typography>
                  <Trans>Add child</Trans>
                </Typography>
              </AddChildButton>
            ) : null}
          </Grid>
        </Grid>
        <HeaderTopColumnRight>
          <LanguageSelector />
        </HeaderTopColumnRight>
      </HeaderTop>

      {hideContact ? null : (
        <TopRightFixedBox>
          <Button
            color="secondary"
            component={RouterLink}
            data-cy="contact-support-button"
            state={{ backgroundLocation: location }}
            sx={{
              direction: "ltr",
              position: "absolute",
              right: "-1px",
              top: "16px",
              transform: "rotate(90deg) translateX(50%)",
              transformOrigin: "top right",
              padding: "0 10px",
              borderColor: colors.zymegoTravertine,
              borderWidth: "2px",
              color: colors.zymegoDarkGreen,
              fontWeight: 600,
              borderTop: 0,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              backgroundColor: colors.zymegoTravertine,
              "&:hover": {
                borderColor: colors.zymegoSand,
                borderWidth: "2px",
                borderTop: 0,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                backgroundColor: colors.zymegoTravertine,
              },
            }}
            to={`/contact${location.search}`}
            variant="outlined"
          >
            <Trans>Support</Trans>
          </Button>
        </TopRightFixedBox>
      )}
    </HeaderWrapper>
  );
}

export { Header };
