import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Button } from "../components/button/button";
import { Drawer } from "../components/drawer/drawer";
import { IconCircle } from "../components/icon-circle/icon-circle";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

function AcceptEarlierAppointmentOverlay() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDate, formatTime } = useDateFormatter();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");

  const [isDialogOpen, setIsDialogOpen] = useState(true);
  function handleDialogClose() {
    setIsDialogOpen(false);
  }
  function handleYesPleaseClick() {
    setIsDialogOpen(false);
    navigate("/");
  }

  const { loading, data: { timeslot } = {} } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  return (
    <Drawer
      isOpen={isDialogOpen}
      onClose={handleDialogClose}
      onExit={() => {
        navigate(
          {
            pathname: backgroundLocation.pathname,
            search: `${searchParameters}`,
          },
          { replace: true },
        );
      }}
    >
      {timeslot && (
        <Box
          sx={{
            alignItems: "center",
            borderBottom: `1px solid ${colors.grey}`,
            color: colors.zymegoDarkGreen,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Typography align="center" fontWeight="600" variant="h4">
            <Trans>You have booked an appointment!</Trans>
          </Typography>
          <Typography
            align="center"
            color="secondary"
            sx={{ ":first-letter": { textTransform: "uppercase" } }}
            variant="h5"
          >
            {formatDate(new Date(timeslot.startAtInCareUnitsTimezone))} &bull;{" "}
            {formatTime(new Date(timeslot.startAtInCareUnitsTimezone))}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          paddingY: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: 1.2,
            textAlign: "center",
          }}
        >
          <Trans>
            Would you be interested if an earlier time slot becomes available?
          </Trans>
        </Typography>
        <Typography
          sx={{
            color: colors.zymegoDarkGreen,
            fontSize: "16px",
            fontWeight: 500,
            opacity: 0.6,
            textAlign: "center",
          }}
        >
          <Trans>We can send offers of earlier time slots.</Trans>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Button
          disabled={loading}
          onClick={handleYesPleaseClick}
          size="large"
          sx={{ margin: 0 }}
        >
          <Trans>Yes, please</Trans>
        </Button>
        <Button
          design="white-bordered"
          disabled={loading}
          onClick={handleDialogClose}
          sx={{ gap: "12px", margin: 0 }}
        >
          <IconCircle color="error" icon="clear" sx={{ margin: 0 }} />
          <Trans>No, thank you</Trans>
        </Button>
      </Box>
    </Drawer>
  );
}

export { AcceptEarlierAppointmentOverlay };
