import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

import { getConfig } from "./runtime-config";
import { userManager } from "./user-manager";
import { logError } from "./utils";

const { API_URL } = getConfig();

// eslint-disable-next-line max-params
const authLink = setContext(async (_, previousContext) => {
  const user = await userManager.getUser();

  const newContext = previousContext;
  if (user) {
    newContext.headers = {
      ...newContext.headers,
      authorization: `${user.token_type} ${user.id_token}`,
    };
  }

  return newContext;
});

const retryAuthLink = new RetryLink({
  delay: {
    initial: 0,
  },
  attempts: {
    max: 2,
    async retryIf(error) {
      if (error?.statusCode === 401) {
        try {
          const user = await userManager.signinSilent();

          if (!user) {
            return false;
          }

          return true;
        } catch {
          return false;
        }
      }

      return false;
    },
  },
});

const logoutLink = onError(({ networkError }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    logError("Signing out due to network error:", networkError);
    userManager.signoutRedirect();
  }
});

const batchHttpLink = new BatchHttpLink({
  uri: API_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([logoutLink, retryAuthLink, authLink, batchHttpLink]),
});

export { client };
